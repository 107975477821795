interface PasswordValidation {
	length: boolean;
	number: boolean;
	uppercase: boolean;
}

interface PasswordValidationCriteriaProps {
	password: string;
	passwordStrength: PasswordValidation;
}

const PasswordValidationCriteria = ({
	password,
	passwordStrength,
}: PasswordValidationCriteriaProps) => {
	return password.length ? (
		<div className='password-strength-indicator'>
			<p
				className={`${
					passwordStrength.length ? 'text-success line-through' : 'text-error'
				}`}
			>
				8 characters
			</p>
			<p
				className={`${
					passwordStrength.number ? 'text-success line-through' : 'text-error'
				}`}
			>
				Number
			</p>
			<p
				className={`${
					passwordStrength.uppercase
						? 'text-success line-through'
						: 'text-error'
				}`}
			>
				Uppercase
			</p>
		</div>
	) : null;
};

export default PasswordValidationCriteria;
