import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import { toastNotification } from '../utils/toastNotification';
import { Helmet } from 'react-helmet';

const Contact = () => {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');

	const handleSendContactUsEmail = async (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		e.preventDefault();

		const data = {
			name,
			email,
			message,
		};

		try {
			const res = await emailjs.send(
				import.meta.env.VITE_EMAIL_JS_SMTP_SERVICE_ID,
				import.meta.env.VITE_EMAIL_JS_CONTACT_US_FORM_TEMPLATE_ID,
				data,
				import.meta.env.VITE_EMAIL_JS_PUBLIC_KEY
			);

			if (res.status === 200)
				toastNotification(
					'Email sent successfully! 🎉',
					'bg-success text-white'
				);

			setName('');
			setEmail('');
			setMessage('');
		} catch (error) {
			console.error('Error sending email:', error);
		}
	};

	return (
		<div className='container my-24 mx-auto md:px-6'>
			<Helmet>
				<meta charSet='utf-8' />
				<meta
					name='description'
					content={`Have a query that you can't find the answer to in our FAQs? Drop us an email and we'll get right back to you.`}
				/>
				<title>Contact Us | Laundro</title>
				<link rel='canonical' href='https://laundro.co.uk/contact' />
			</Helmet>

			<section className='mb-32 text-center'>
				<div className='py-12 md:px-12'>
					<div className='container mx-auto xl:px-32'>
						<div className='grid items-center'>
							<div className='mb-12 md:mt-12 lg:mt-0 lg:mb-0'>
								<div className='relative z-[1] block rounded-lg bg-[hsla(0,0%,100%,0.55)] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] backdrop-blur-[30px] md:px-12 lg:-mr-14'>
									<h2 className='mb-12 text-3xl font-bold text-gray-700'>
										Contact us
									</h2>
									<form>
										<div className='relative mb-6'>
											<input
												type='text'
												className='block py-1 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-primary peer'
												placeholder=' '
												value={name}
												onChange={(e) => setName(e.target.value)}
											/>
											<label className='absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-0 left-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'>
												Full Name
											</label>
										</div>

										<div className='relative mb-6'>
											<input
												type='text'
												className='block py-1 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-primary peer'
												placeholder=' '
												value={email}
												onChange={(e) => setEmail(e.target.value)}
											/>
											<label className='absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-0 left-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'>
												Email Address
											</label>
										</div>

										<div className='relative mb-6'>
											<textarea
												className='block py-1 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-primary peer'
												rows={3}
												placeholder=' '
												value={message}
												onChange={(e) => setMessage(e.target.value)}
											/>
											<label className='absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-0 left-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'>
												Message
											</label>
										</div>

										<button
											type='button'
											className='inline-block w-full rounded bg-primary px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] lg:mb-0'
											onClick={handleSendContactUsEmail}
										>
											Send
										</button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default Contact;
