import Confetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize';

const PreRegistered = () => {
	const { width, height } = useWindowSize();

	return (
		<div className='h-screen flex justify-center items-center'>
			<div className='card bg-base-200 p-10 prose text-center'>
				<h1>Thank you for pre-registering 🎉</h1>
				<p>
					This is just to confirm that you have subscribed to be notified when
					our services are officially live!
				</p>
			</div>
			<Confetti
				width={width}
				height={height}
				colors={['#9FB8AD', '#440381', '#475841', '#FFA5A5', '#FA7921']}
			/>
		</div>
	);
};

export default PreRegistered;
