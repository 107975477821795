import { useState } from 'react';
import { findAddressByPostcode, updateUser } from '../utils/api';
import { useStateContext } from '../context/ContextProvider';
import { toastNotification } from '../utils/toastNotification';
import availablePostcodes from '../data/availablePostcodes.json';

const AddAddressModal = () => {
  const { loggedInUser, loggedInUserToken, setLoggedInUser } =
    useStateContext()!;

  const [houseNo, setHouseNo] = useState('');
  const [street, setstreet] = useState('');
  const [city, setCity] = useState('');
  const [postcode, setPostcode] = useState('');

  const handleAddAddress = async () => {
    try {
      const address = await findAddressByPostcode(postcode);

      if (
        address.header.query.toLocaleLowerCase().replace(' ', '').trim() ===
        address.results[0].GAZETTEER_ENTRY.ID.toLocaleLowerCase()
      ) {
        if (
          availablePostcodes.includes(
            postcode.replace(' ', '').toLocaleUpperCase()
          )
        ) {
          const updatedUser = {
            ...loggedInUser,
            houseNo,
            street,
            city,
            postcode,
          };

          // Update the local storage directly
          localStorage.setItem(
            'loggedInUserInfo',
            JSON.stringify({
              user: updatedUser,
              token: loggedInUserToken,
            })
          );

          setLoggedInUser({ ...loggedInUser, ...updatedUser });

          await updateUser(loggedInUser.id, loggedInUserToken, updatedUser);
        } else {
          toastNotification(
            `Sorry, we don't cover your area yet!`,
            'bg-warning'
          );
        }
      } else {
        toastNotification('Invalid Postcode', 'bg-error text-white');
      }
    } catch (error) {
      console.log(
        '%cAddAddressModal.tsx line:13 error',
        'color: #26bfa5;',
        error
      );
    }
  };

  return (
    <div>
      <button
        className='btn btn-warning'
        onClick={() =>
          (
            document.getElementById('set_address_modal') as HTMLDialogElement
          )?.showModal()
        }
      >
        Set Your Address
      </button>

      <dialog id='set_address_modal' className='modal'>
        <div className='modal-box'>
          <h3 className='font-bold text-lg'>Please add an address</h3>
          <div className='form-control'>
            <label className='label'>
              <span className='label-text'>House Name/No.</span>
            </label>
            <input
              type='text'
              placeholder='24'
              className='input input-bordered'
              onChange={(e) => setHouseNo(e.target.value)}
              required
            />
          </div>
          <div className='form-control'>
            <label className='label'>
              <span className='label-text'>Street Name</span>
            </label>
            <input
              type='text'
              placeholder='Springfield Lane'
              className='input input-bordered'
              onChange={(e) => setstreet(e.target.value)}
              required
            />
          </div>
          <div className='form-control'>
            <label className='label'>
              <span className='label-text'>City</span>
            </label>
            <input
              type='text'
              placeholder='Bradford'
              className='input input-bordered'
              onChange={(e) => setCity(e.target.value)}
              required
            />
          </div>
          <div className='form-control'>
            <label className='label'>
              <span className='label-text'>Postcode</span>
            </label>
            <input
              type='text'
              placeholder='BD2 1XX'
              className='input input-bordered'
              onChange={(e) => setPostcode(e.target.value)}
              required
            />
          </div>

          <div className='modal-action'>
            <form method='dialog'>
              <button className='btn btn-ghost text-error'>Cancel</button>
              <button
                className='btn btn-success text-base-100'
                onClick={handleAddAddress}
                disabled={
                  houseNo === '' ||
                  street === '' ||
                  city === '' ||
                  postcode === ''
                }
              >
                Save Address
              </button>
            </form>
          </div>
        </div>
      </dialog>
    </div>
  );
};

export default AddAddressModal;
