import { UsersOrderData } from '@/types';

const groupOrdersByDate = (orders: UsersOrderData[]) => {
  const currentDate = new Date();

  //   // Grouping by week
  // Calculate the start date of the current week (today - 4 days)
  const startOfWeek = new Date(currentDate);
  startOfWeek.setDate(currentDate.getDate() - 4);

  // Calculate the end date of the current week (today + 3 days)
  const endOfWeek = new Date(currentDate);
  endOfWeek.setDate(currentDate.getDate() + 3);

  // Grouping by week
  const ordersThisWeek = orders
    .filter((order) => {
      const collectionDate = new Date(order.collectionDate);
      return collectionDate >= startOfWeek && collectionDate <= endOfWeek;
    })
    .sort(
      (a, b) =>
        new Date(a.collectionDate).getTime() -
        new Date(b.collectionDate).getTime()
    );

  // Grouping by month
  const ordersThisMonth = orders
    .filter((order) => {
      const collectionDate = new Date(order.collectionDate);
      return (
        collectionDate.getMonth() === currentDate.getMonth() &&
        collectionDate.getFullYear() === currentDate.getFullYear()
      );
    })
    .sort(
      (a, b) =>
        new Date(a.collectionDate).getTime() -
        new Date(b.collectionDate).getTime()
    );

  // Grouping by year
  const ordersThisYear = orders
    .filter((order) => {
      const collectionDate = new Date(order.collectionDate);
      return collectionDate.getFullYear() === currentDate.getFullYear();
    })
    .sort(
      (a, b) =>
        new Date(a.collectionDate).getTime() -
        new Date(b.collectionDate).getTime()
    );

  return {
    thisWeek: ordersThisWeek,
    thisMonth: ordersThisMonth,
    thisYear: ordersThisYear,
  };
};

export default groupOrdersByDate;
