import { useLocation } from 'react-router-dom';
import ServicePage from '../../components/Services/ServicePage';

const WashDryAndIron = () => {
  const { pathname } = useLocation();
  const service = pathname.split('/laundry/services/')[1];

  return <ServicePage service={service} />;
};

export default WashDryAndIron;
