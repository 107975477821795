import { toastNotification } from '@/utils/toastNotification';
import { Separator } from '@/components/ui/separator';
import { Link } from 'react-router-dom';
import { Button } from '../ui/button';
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  CardFooter,
} from '@/components/ui/card';
import OrderStatusButton from './OrderStatusButton';
import { FaRegCopy } from 'react-icons/fa6';
import { UsersOrderData } from '@/types';

interface CurrentOrderPopoutProps {
  currentOrder: UsersOrderData;
  setCurrentOrder: React.Dispatch<
    React.SetStateAction<UsersOrderData | undefined>
  >;
}

const CurrentOrderPopout = ({
  currentOrder,
  setCurrentOrder,
}: CurrentOrderPopoutProps) => {
  const deliveryChargeMapper = (deliveryChargeId: number) => {
    if (deliveryChargeId === 1) {
      return 'Free';
    } else {
      return `£3.00`;
    }
  };

  return (
    <Card className='overflow-hidden' x-chunk='dashboard-05-chunk-4'>
      <CardHeader className='flex flex-row items-start bg-muted/50'>
        <div className='grid gap-0.5'>
          <CardTitle className='group flex justify-between items-center'>
            <div className='flex justify-center items-center gap-2'>
              <p>Order {currentOrder?.id}</p>
              <Button
                size='icon'
                variant='outline'
                className='h-6 w-6 opacity-0 transition-opacity group-hover:opacity-100 text-lg'
                onClick={() => {
                  navigator.clipboard.writeText(`${currentOrder.id}`);
                  toastNotification(
                    `Copied order ID to clipboard`,
                    'bg-success text-white'
                  );
                }}
              >
                <FaRegCopy className='h-3 w-3' />
                <span className='sr-only'>Copy Order ID</span>
              </Button>
            </div>
            <p className='text-sm'>
              {new Date(currentOrder.createdAt).toLocaleDateString('en-GB')}
            </p>
          </CardTitle>
          <CardDescription>
            <div className='flex justify-between items-center'>
              <div className='flex flex-col gap-2'>
                <p>
                  Collection Date:{' '}
                  {new Date(currentOrder.collectionDate).toLocaleDateString(
                    'en-GB'
                  )}
                </p>
              </div>
              <OrderStatusButton
                currentOrder={currentOrder}
                setCurrentOrder={setCurrentOrder}
              />
            </div>
          </CardDescription>
        </div>
      </CardHeader>
      <CardContent className='p-6 text-sm'>
        <div className='grid gap-3'>
          <div className='font-semibold'>Order Details</div>
          <ul className='grid gap-3'>
            {currentOrder.basketItems.map((item) => (
              <li key={item.id} className='flex items-center justify-between'>
                <span className='text-muted-foreground'>
                  <p>{item.title}</p> x <span>2</span>
                </span>
                <span>£{item.price.toFixed(2)}</span>
              </li>
            ))}
          </ul>
          <Separator className='my-2' />
          <ul className='grid gap-3'>
            <li className='flex items-center justify-between'>
              <span className='text-muted-foreground'>Delivery Charge</span>
              <span>
                {deliveryChargeMapper(currentOrder.deliveryMethod.id)}
              </span>
            </li>
            <li className='flex items-center justify-between font-semibold'>
              <span className='text-muted-foreground'>Total</span>
              <span>£{currentOrder.totalPrice.toFixed(2)}</span>
            </li>
          </ul>
        </div>
        <Separator className='my-4' />
        <div className='grid gap-3'>
          <div className='font-semibold'>Customer Address</div>
          <address className='grid gap-0.5 not-italic text-muted-foreground'>
            <span>{currentOrder.user.fullName}</span>
            <span>
              {currentOrder.user.houseNo} {currentOrder.user.street}
            </span>
            <span>{currentOrder.user.postcode?.toUpperCase()}</span>
          </address>
        </div>

        <Separator className='my-4' />
        <div className='grid gap-3'>
          <div className='font-semibold'>Customer Information</div>
          <dl className='grid gap-3'>
            <div className='flex items-center justify-between'>
              <dt className='text-muted-foreground'>Customer</dt>
              <dd>{currentOrder.user.fullName}</dd>
            </div>
            <div className='flex items-center justify-between'>
              <dt className='text-muted-foreground'>Email</dt>
              <dd>
                <Link to='mailto:'>{currentOrder.user.email}</Link>
              </dd>
            </div>
            <div className='flex items-center justify-between'>
              <dt className='text-muted-foreground'>Phone</dt>
              <dd>
                {currentOrder.user.phoneNumber ? (
                  <Link to='tel:'>{currentOrder.user.phoneNumber}</Link>
                ) : (
                  'No number provided'
                )}
              </dd>
            </div>
          </dl>
        </div>
      </CardContent>

      {currentOrder.notes ? (
        <CardFooter className='flex flex-row items-center border-t bg-muted/50 px-6 py-3'>
          <div className='text-xs text-muted-foreground'>
            Customer Notes: {currentOrder.notes}
          </div>
        </CardFooter>
      ) : null}
    </Card>
  );
};

export default CurrentOrderPopout;
