import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Input } from '@/components/ui/input';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useStateContext } from '@/context/ContextProvider';
import { LoggedInUser, UsersOrderData } from '@/types';
import { getAllOrders, getAllUsers } from '@/utils/api';
import { useEffect, useState } from 'react';

import { CiSearch } from 'react-icons/ci';
import { CiExport } from 'react-icons/ci';
import { IoFilterOutline } from 'react-icons/io5';

import groupOrdersByDate from '@/utils/groupOrdersByDate';
import calculateTotalRevenueForPeriod from '@/utils/calculateTotalRevenueForPeriod';
import SkeletonCard from '@/components/Dashboard/SkeletonCard';
import { Badge } from '@/components/ui/badge';
import CurrentOrderPopout from '@/components/Dashboard/CurrentOrderPopout';
import getStatusBadgeVariant from '@/utils/getStatusBadgeVariant';
import getStatusLabel from '@/utils/getStatusLabel';

const Dashboard = () => {
  const { loggedInUser, loggedInUserToken } = useStateContext()!;

  const [ordersData, setOrdersData] = useState<UsersOrderData[]>([]);
  const [usersData, setUsersData] = useState<LoggedInUser[]>([]);
  const [currentOrder, setCurrentOrder] = useState<UsersOrderData | undefined>(
    undefined
  );
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [currentTab, setCurrentTab] = useState('week');
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchQuery(event.target.value);
  };

  const handleFilterSelect = (filter: string) => {
    setSelectedFilter(filter);
  };

  useEffect(() => {
    setCurrentOrder(undefined);
  }, [currentTab]);

  const handleOrderClick = (order: UsersOrderData) => {
    setCurrentOrder(order);
  };

  const groupedOrders = groupOrdersByDate(ordersData);

  const thisMonthsTotalRevenue = calculateTotalRevenueForPeriod(
    groupedOrders.thisMonth
  );

  const thisYearsTotalRevenue = calculateTotalRevenueForPeriod(
    groupedOrders.thisYear
  );
  useEffect(() => {
    const fetchData = async () => {
      try {
        const orders: UsersOrderData[] = await getAllOrders(
          loggedInUserToken,
          loggedInUser
        );

        const sortedOrdersByDate = orders.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );

        setOrdersData(sortedOrdersByDate);

        // ----------------------------------------------------------------

        const users = await getAllUsers(loggedInUserToken, loggedInUser);
        setUsersData(users);

        if (orders && users) {
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [ordersData]);

  return (
    <div className='flex min-h-screen w-full flex-col bg-muted/40'>
      <div className='flex flex-col sm:gap-4 sm:py-4 sm:pl-14'>
        <header className='sticky top-0 z-0 flex h-14 items-center gap-4 border-b bg-background px-4 sm:static sm:h-auto sm:border-0 sm:bg-transparent sm:px-6'>
          <div className='relative ml-auto flex-1 md:grow-0'>
            <CiSearch className='absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground' />
            <Input
              type='search'
              placeholder='Search...'
              className='w-full rounded-lg bg-background pl-8 md:w-[200px] lg:w-[320px]'
              onChange={handleSearchInputChange}
            />
          </div>
        </header>
        <main className='grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8 lg:grid-cols-3 xl:grid-cols-3'>
          <div className='grid auto-rows-max items-start gap-4 md:gap-8 lg:col-span-2'>
            <div className='grid gap-4 sm:grid-cols-2 grid-cols-1'>
              <Card x-chunk='dashboard-05-chunk-1'>
                <CardHeader className='pb-2'>
                  {isLoading ? (
                    <SkeletonCard />
                  ) : (
                    <>
                      <CardDescription>Users</CardDescription>
                      <CardTitle className='text-4xl'>
                        {usersData.length}
                      </CardTitle>
                    </>
                  )}
                </CardHeader>
              </Card>

              <Card x-chunk='dashboard-05-chunk-2'>
                <CardHeader className='pb-2'>
                  {isLoading ? (
                    <SkeletonCard />
                  ) : (
                    <>
                      <CardDescription>Total Orders</CardDescription>
                      <CardTitle className='text-4xl'>
                        {ordersData.length}
                      </CardTitle>
                    </>
                  )}
                </CardHeader>
              </Card>

              <Card x-chunk='dashboard-05-chunk-3'>
                <CardHeader className='pb-2'>
                  {isLoading ? (
                    <SkeletonCard />
                  ) : (
                    <>
                      <CardDescription>This Month</CardDescription>
                      <CardTitle className='text-4xl'>
                        £{thisMonthsTotalRevenue.toFixed(2)}
                      </CardTitle>
                    </>
                  )}
                </CardHeader>
              </Card>

              <Card x-chunk='dashboard-05-chunk-4'>
                <CardHeader className='pb-2'>
                  {isLoading ? (
                    <SkeletonCard />
                  ) : (
                    <>
                      <CardDescription>This Year</CardDescription>
                      <CardTitle className='text-4xl'>
                        £{thisYearsTotalRevenue.toFixed(2)}
                      </CardTitle>
                    </>
                  )}
                </CardHeader>
              </Card>
            </div>
            <Tabs defaultValue='week'>
              <div className='flex items-center'>
                <TabsList>
                  <TabsTrigger
                    onClick={() => setCurrentTab('week')}
                    value='week'
                  >
                    Week
                  </TabsTrigger>
                  <TabsTrigger
                    onClick={() => setCurrentTab('month')}
                    value='month'
                  >
                    Month
                  </TabsTrigger>
                  <TabsTrigger
                    onClick={() => setCurrentTab('year')}
                    value='year'
                  >
                    Year
                  </TabsTrigger>
                </TabsList>
                <div className='ml-auto flex items-center gap-2'>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button
                        variant='outline'
                        size='sm'
                        className='h-7 gap-1 text-sm'
                      >
                        <IoFilterOutline className='h-3.5 w-3.5' />
                        <span className='sr-only sm:not-sr-only'>Filter</span>
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align='end' className='bg-white'>
                      <DropdownMenuLabel>Filter by</DropdownMenuLabel>
                      <DropdownMenuSeparator />
                      <DropdownMenuRadioGroup defaultValue={'all'}>
                        <DropdownMenuRadioItem
                          defaultChecked
                          value='all'
                          id='all'
                          onSelect={() => handleFilterSelect('all')}
                        >
                          All
                        </DropdownMenuRadioItem>
                        <DropdownMenuRadioItem
                          value='confirmed'
                          id='confirmed'
                          onSelect={() => handleFilterSelect('confirmed')}
                        >
                          Ready to Collect
                        </DropdownMenuRadioItem>
                        <DropdownMenuRadioItem
                          value='collected'
                          id='collected'
                          onSelect={() => handleFilterSelect('collected')}
                        >
                          Collected
                        </DropdownMenuRadioItem>
                        <DropdownMenuRadioItem
                          value='delivered'
                          id='delivered'
                          onSelect={() => handleFilterSelect('delivered')}
                        >
                          Completed
                        </DropdownMenuRadioItem>
                        <DropdownMenuRadioItem
                          value='cancelled'
                          id='cancelled'
                          onSelect={() => handleFilterSelect('cancelled')}
                        >
                          Cancelled
                        </DropdownMenuRadioItem>
                      </DropdownMenuRadioGroup>
                    </DropdownMenuContent>
                  </DropdownMenu>

                  <Button
                    size='sm'
                    variant='outline'
                    className='h-7 gap-1 text-sm'
                  >
                    <CiExport className='h-3.5 w-3.5' />
                    <span className='sr-only sm:not-sr-only'>Export</span>
                  </Button>
                </div>
              </div>

              <TabsContent value='week'>
                <Card x-chunk='dashboard-05-chunk-3'>
                  <CardHeader className='px-7'>
                    <CardTitle>Orders</CardTitle>
                    <CardDescription>
                      Made from our lovely customers.
                    </CardDescription>
                  </CardHeader>
                  <CardContent>
                    <Table>
                      <TableHeader>
                        <TableRow>
                          <TableHead>ID</TableHead>
                          <TableHead className='hidden sm:table-cell'>
                            Customer
                          </TableHead>
                          <TableHead className='hidden sm:table-cell'>
                            Notes
                          </TableHead>
                          <TableHead className='hidden sm:table-cell'>
                            Collection Date
                          </TableHead>
                          <TableHead className='hidden sm:table-cell'>
                            Status
                          </TableHead>
                          <TableHead className='hidden md:table-cell'>
                            Total Spend
                          </TableHead>
                        </TableRow>
                      </TableHeader>

                      <TableBody>
                        {groupedOrders.thisWeek
                          .filter((order) => {
                            const matchesSearchQuery =
                              order.user.fullName
                                ?.toLowerCase()
                                .includes(searchQuery.toLowerCase()) ||
                              order.notes
                                ?.toLowerCase()
                                .includes(searchQuery.toLowerCase()) ||
                              order.totalPrice
                                ?.toString()
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase()) ||
                              order.id
                                ?.toString()
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase());

                            switch (selectedFilter) {
                              case 'confirmed':
                                return (
                                  order.confirmed &&
                                  !order.collected &&
                                  !order.delivered &&
                                  !order.cancelled &&
                                  matchesSearchQuery
                                );
                              case 'collected':
                                return (
                                  order.collected &&
                                  !order.delivered &&
                                  !order.cancelled &&
                                  matchesSearchQuery
                                );
                              case 'delivered':
                                return (
                                  order.delivered &&
                                  !order.cancelled &&
                                  matchesSearchQuery
                                );
                              case 'cancelled':
                                return order.cancelled && matchesSearchQuery;
                              case 'all':
                                return order && matchesSearchQuery;
                              default:
                                return false;
                            }
                          })
                          .map((order) => (
                            <TableRow
                              key={order.id}
                              onClick={() => handleOrderClick(order)}
                              className='hover:bg-accent hover:cursor-pointer hover:rounded-lg'
                            >
                              <TableCell>{order.id}</TableCell>
                              <TableCell>{order.user.fullName}</TableCell>
                              <TableCell>{order.notes}</TableCell>
                              <TableCell>
                                {new Date(
                                  order.collectionDate
                                ).toLocaleDateString('en-GB', {
                                  day: 'numeric',
                                  month: 'long',
                                  year: 'numeric',
                                })}
                              </TableCell>
                              <TableCell className='hidden sm:table-cell'>
                                <Badge
                                  className={`text-xs text-white ${getStatusBadgeVariant(
                                    order
                                  )}`}
                                >
                                  {getStatusLabel(order)}
                                </Badge>
                              </TableCell>
                              <TableCell>
                                £{order.totalPrice.toFixed(2)}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
              </TabsContent>

              <TabsContent value='month'>
                <Card x-chunk='dashboard-05-chunk-3'>
                  <CardHeader className='px-7'>
                    <CardTitle>Orders</CardTitle>
                    <CardDescription>
                      Made from our lovely customers.
                    </CardDescription>
                  </CardHeader>
                  <CardContent>
                    <Table>
                      <TableHeader>
                        <TableRow>
                          <TableHead>ID</TableHead>
                          <TableHead className='hidden sm:table-cell'>
                            Customer
                          </TableHead>
                          <TableHead className='hidden sm:table-cell'>
                            Notes
                          </TableHead>
                          <TableHead className='hidden sm:table-cell'>
                            Collection
                          </TableHead>
                          <TableHead className='hidden sm:table-cell'>
                            Status
                          </TableHead>
                          <TableHead className='hidden md:table-cell'>
                            Total Spend
                          </TableHead>
                        </TableRow>
                      </TableHeader>

                      <TableBody>
                        {groupedOrders.thisMonth
                          .filter((order) => {
                            const matchesSearchQuery =
                              order.user.fullName
                                ?.toLowerCase()
                                .includes(searchQuery.toLowerCase()) ||
                              order.notes
                                ?.toLowerCase()
                                .includes(searchQuery.toLowerCase()) ||
                              order.totalPrice
                                ?.toString()
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase()) ||
                              order.id
                                ?.toString()
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase());

                            switch (selectedFilter) {
                              case 'confirmed':
                                return (
                                  order.confirmed &&
                                  !order.collected &&
                                  !order.delivered &&
                                  !order.cancelled &&
                                  matchesSearchQuery
                                );
                              case 'collected':
                                return (
                                  order.collected &&
                                  !order.delivered &&
                                  !order.cancelled &&
                                  matchesSearchQuery
                                );
                              case 'delivered':
                                return (
                                  order.delivered &&
                                  !order.cancelled &&
                                  matchesSearchQuery
                                );
                              case 'cancelled':
                                return order.cancelled && matchesSearchQuery;
                              case 'all':
                                return order && matchesSearchQuery;
                              default:
                                return false;
                            }
                          })
                          .map((order) => (
                            <TableRow
                              key={order.id}
                              onClick={() => handleOrderClick(order)}
                              className='hover:bg-accent hover:cursor-pointer hover:rounded-lg'
                            >
                              <TableCell>{order.id}</TableCell>
                              <TableCell>{order.user.fullName}</TableCell>
                              <TableCell>{order.notes}</TableCell>
                              <TableCell>
                                {new Date(
                                  order.collectionDate
                                ).toLocaleDateString('en-GB', {
                                  day: 'numeric',
                                  month: 'long',
                                  year: 'numeric',
                                })}
                              </TableCell>
                              <TableCell className='hidden sm:table-cell'>
                                <Badge
                                  className={`text-xs text-white ${getStatusBadgeVariant(
                                    order
                                  )}`}
                                >
                                  {getStatusLabel(order)}
                                </Badge>
                              </TableCell>
                              <TableCell>
                                £{order.totalPrice.toFixed(2)}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
              </TabsContent>

              <TabsContent value='year'>
                <Card x-chunk='dashboard-05-chunk-3'>
                  <CardHeader className='px-7'>
                    <CardTitle>Orders</CardTitle>
                    <CardDescription>
                      Made from our lovely customers.
                    </CardDescription>
                  </CardHeader>
                  <CardContent>
                    <Table>
                      <TableHeader>
                        <TableRow>
                          <TableHead>ID</TableHead>
                          <TableHead className='hidden sm:table-cell'>
                            Customer
                          </TableHead>
                          <TableHead className='hidden sm:table-cell'>
                            Notes
                          </TableHead>
                          <TableHead className='hidden sm:table-cell'>
                            Collection
                          </TableHead>
                          <TableHead className='hidden sm:table-cell'>
                            Status
                          </TableHead>
                          <TableHead className='hidden md:table-cell'>
                            Total Spend
                          </TableHead>
                        </TableRow>
                      </TableHeader>

                      <TableBody>
                        {groupedOrders.thisYear
                          .filter((order) => {
                            const matchesSearchQuery =
                              order.user.fullName
                                ?.toLowerCase()
                                .includes(searchQuery.toLowerCase()) ||
                              order.notes
                                ?.toLowerCase()
                                .includes(searchQuery.toLowerCase()) ||
                              order.totalPrice
                                ?.toString()
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase()) ||
                              order.id
                                ?.toString()
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase());

                            switch (selectedFilter) {
                              case 'confirmed':
                                return (
                                  order.confirmed &&
                                  !order.collected &&
                                  !order.delivered &&
                                  !order.cancelled &&
                                  matchesSearchQuery
                                );
                              case 'collected':
                                return (
                                  order.collected &&
                                  !order.delivered &&
                                  !order.cancelled &&
                                  matchesSearchQuery
                                );
                              case 'delivered':
                                return (
                                  order.delivered &&
                                  !order.cancelled &&
                                  matchesSearchQuery
                                );
                              case 'cancelled':
                                return order.cancelled && matchesSearchQuery;
                              case 'all':
                                return order && matchesSearchQuery;
                              default:
                                return false;
                            }
                          })
                          .map((order) => (
                            <TableRow
                              key={order.id}
                              onClick={() => handleOrderClick(order)}
                              className='hover:bg-accent hover:cursor-pointer hover:rounded-lg'
                            >
                              <TableCell>{order.id}</TableCell>
                              <TableCell>{order.user.fullName}</TableCell>
                              <TableCell>{order.notes}</TableCell>
                              <TableCell>
                                {new Date(
                                  order.collectionDate
                                ).toLocaleDateString('en-GB', {
                                  day: 'numeric',
                                  month: 'long',
                                  year: 'numeric',
                                })}
                              </TableCell>
                              <TableCell className='hidden sm:table-cell'>
                                <Badge
                                  className={`text-xs text-white ${getStatusBadgeVariant(
                                    order
                                  )}`}
                                >
                                  {getStatusLabel(order)}
                                </Badge>
                              </TableCell>
                              <TableCell>
                                £{order.totalPrice.toFixed(2)}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
              </TabsContent>
            </Tabs>
          </div>

          {currentOrder ? (
            <CurrentOrderPopout
              currentOrder={currentOrder}
              setCurrentOrder={setCurrentOrder}
            />
          ) : null}
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
