import { useStateContext } from '@/context/ContextProvider';

const CheckoutSteps = () => {
  const { orderData, selectedDate } = useStateContext()!;

  return (
    <div className='flex justify-center items-center mb-10'>
      <ul className='steps sm:w-2/3 w-full'>
        <li
          className={`step ${
            orderData.basketItems.length !== 0 && 'step-primary'
          }`}
        >
          Add Services
        </li>
        <li
          className={`step ${
            orderData.basketItems.length !== 0 &&
            selectedDate !== '' &&
            'step-primary'
          }`}
        >
          Select Date
        </li>
        <li
          className={`step ${
            orderData.basketItems.length !== 0 &&
            selectedDate !== '' &&
            'step-secondary'
          }`}
        >
          Place Order!
        </li>
      </ul>
    </div>
  );
};

export default CheckoutSteps;
