const WaistcoatSvg = () => {
	return (
		<g>
			<path d='m11.32.86c4.77,1.59,9.58,1.55,14.44-.02.58-.19,1.2-.16,1.76.08l3.75,1.61c.66.28,1.04,1,.89,1.7-.77,3.76-2.58,15.2,2.94,18.07.62.32,1,.98,1,1.68v18.31s-11.39,5.29-11.39,5.29c-.82.38-1.8.09-2.28-.68l-3.91-6.3-4.05,6.31c-.49.77-1.48,1.04-2.3.64L.72,41.92v-18.29c0-.63.31-1.22.84-1.56,1.89-1.21,6.08-5.39,2.99-17.62-.18-.73.19-1.49.88-1.78l4.11-1.75c.57-.24,1.2-.26,1.78-.06Z' />
			<polyline points='11.32 .86 18.52 19.83 25.76 .85' />
			<line x1={18.52} y1={19.83} x2={18.52} y2={40.6} />
			<circle className='cls-2' cx={21.35} cy={26.12} r={0.3} />
			<circle className='cls-2' cx={21.35} cy={30.93} r={0.3} />
			<path d='m25.76,34.2l7.08-1.61v2.77c0,.4-.18.77-.5,1.01l-1.87,1.43c-.4.31-.92.42-1.42.31l-2.31-.51c-.58-.13-.99-.64-.99-1.24v-2.16Z' />
			<path d='m11.01,34.2l-7.08-1.61v2.77c0,.4.18.77.5,1.01l1.87,1.43c.4.31.92.42,1.42.31l2.31-.51c.58-.13.99-.64.99-1.24v-2.16Z' />
		</g>
	);
};

export default WaistcoatSvg;
