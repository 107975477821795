import { useEffect, useState } from 'react';
import { useStateContext } from '../context/ContextProvider';
import PasswordHider from '../components/PasswordHider';
import { toastNotification } from '../utils/toastNotification';
import { updateUserPassword } from '../utils/api';
import { useNavigate } from 'react-router-dom';

const PasswordReset = () => {
  const { OTP, setLoggedInUser, passwordResetEmail } = useStateContext()!;

  const navigate = useNavigate();

  const [userOTP1, setUserOTP1] = useState('');
  const [userOTP2, setUserOTP2] = useState('');
  const [userOTP3, setUserOTP3] = useState('');
  const [userOTP4, setUserOTP4] = useState('');
  const [userOTP5, setUserOTP5] = useState('');
  const [userOTP6, setUserOTP6] = useState('');
  const [isOtpValid, setIsOtpValid] = useState(false);

  const [userPassword, setUserPassword] = useState('');
  const [userConfirmedPassword, setUserConfirmedPassword] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const formattedOTP = `${userOTP1}${userOTP2}${userOTP3}${userOTP4}${userOTP5}${userOTP6}`;

  useEffect(() => {
    setIsOtpValid(+OTP === +formattedOTP);
  }, [userOTP1, userOTP2, userOTP3, userOTP4, userOTP5, userOTP6]);

  const handleCheckOTP = () => {
    if (!isOtpValid) {
      toastNotification('Code is invalid', 'bg-error text-white');
      return;
    }
  };

  const handlePasswordReset = async () => {
    const passwordsMatch = userPassword === userConfirmedPassword;

    try {
      if (userPassword && userConfirmedPassword) {
        if (passwordsMatch) {
          const updatedUser = await updateUserPassword(
            passwordResetEmail,
            userPassword
          );

          localStorage.setItem('loggedInUserInfo', JSON.stringify(updatedUser));

          const storedUserInfo = localStorage.getItem('loggedInUserInfo')!;
          setLoggedInUser(JSON.parse(storedUserInfo));

          toastNotification('Password successfully updated', 'bg-success');

          setTimeout(() => {
            navigate('/profile');
            window.location.reload();
          }, 2000);
        } else {
          toastNotification('Passwords do not match', 'bg-error text-base-100');
        }
      } else {
        toastNotification(
          'Please enter an email and a password',
          'bg-error text-base-100'
        );
      }
    } catch (error) {
      console.log(error);
      if (!userPassword)
        toastNotification('Please enter a password', 'bg-error text-base-100');
    }
  };

  return (
    <div className='w-full flex justify-center items-center0 min-h-[60vh]'>
      {!isOtpValid ? (
        <div className='prose flex flex-col justify-center items-center'>
          <h1>Please enter the code sent to your email.</h1>
          {/* INPUTS */}
          <div className='flex gap-4'>
            <input
              type='text'
              className='border px-2 py-2 w-10 rounded-lg flex justify-center items-center'
              maxLength={1}
              onChange={(e) => setUserOTP1(e.target.value)}
            />
            <input
              type='text'
              className='border px-2 py-2 w-10 rounded-lg flex justify-center items-center'
              maxLength={1}
              onChange={(e) => setUserOTP2(e.target.value)}
            />
            <input
              type='text'
              className='border px-2 py-2 w-10 rounded-lg flex justify-center items-center'
              maxLength={1}
              onChange={(e) => setUserOTP3(e.target.value)}
            />
            <input
              type='text'
              className='border px-2 py-2 w-10 rounded-lg flex justify-center items-center'
              maxLength={1}
              onChange={(e) => setUserOTP4(e.target.value)}
            />
            <input
              type='text'
              className='border px-2 py-2 w-10 rounded-lg flex justify-center items-center'
              maxLength={1}
              onChange={(e) => setUserOTP5(e.target.value)}
            />
            <input
              type='text'
              className='border px-2 py-2 w-10 rounded-lg flex justify-center items-center'
              maxLength={1}
              onChange={(e) => setUserOTP6(e.target.value)}
            />
            <button className='btn btn-primary' onClick={handleCheckOTP}>
              GO
            </button>
          </div>
        </div>
      ) : (
        <div className='card flex-shrink-0 w-full max-w-md shadow-2xl bg-base-100'>
          <div className='card-body'>
            {/* PASSWORD */}
            <div className='form-control flex-2'>
              <label className='label'>
                <span className='label-text'>Password</span>
              </label>
              <div className='flex'>
                <input
                  type={isPasswordVisible ? 'text' : 'password'}
                  placeholder='password'
                  className='input input-bordered flex-1'
                  onChange={(e) => setUserPassword(e.target.value)}
                  required
                />
                <PasswordHider
                  isPasswordVisible={isPasswordVisible}
                  setIsPasswordVisible={setIsPasswordVisible}
                />
              </div>
            </div>

            {/* CONFIRM PASSWORD */}
            <div className='form-control'>
              <label className='label'>
                <span className='label-text'>Confirm Password</span>
              </label>
              <div className='flex'>
                <input
                  type={isPasswordVisible ? 'text' : 'password'}
                  placeholder='retype password'
                  className='input input-bordered flex-1'
                  onChange={(e) => setUserConfirmedPassword(e.target.value)}
                  required
                />
                <PasswordHider
                  isPasswordVisible={isPasswordVisible}
                  setIsPasswordVisible={setIsPasswordVisible}
                />
              </div>
            </div>
            <div className='form-control mt-6'>
              <button className='btn btn-primary' onClick={handlePasswordReset}>
                Confirm Reset
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PasswordReset;
