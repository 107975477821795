import { Link } from 'react-router-dom';
import { ServiceType } from '../../types';
import { getLowestPrice } from '@/utils/getLowestPrice';

const DesktopServiceCard = ({ serviceType }: { serviceType: ServiceType }) => {
  return (
    <Link
      to={`/laundry/services/${serviceType.shortCode}`}
      className='card w-full sm:w-96 shadow-xl image-full no-underline z-0 group relative overflow-hidden rounded-xl'
    >
      <figure className='h-40 md:h-48'>
        <img
          src={serviceType.icon}
          alt={serviceType.title}
          className='w-full object-cover object-bottom'
        />
      </figure>

      <div className='flex gap-1 absolute top-4 right-4 z-10'>
        {serviceType.tags.map((tag) => (
          <div key={tag} className='badge badge-sm badge-ghost'>
            {tag}
          </div>
        ))}
      </div>

      <div className='pointer-events-none z-10 flex transform-gpu flex-col justify-end items-start gap-1 p-6 transition-all duration-300 group-hover:-translate-y-4'>
        <div className='bg-black/70 h-full -bottom-[100px] absolute w-full z-0 left-0 rounded-t-2xl'></div>

        <h1 className='text-lg font-bold text-neutral-700 dark:text-neutral-300 z-10'>
          {serviceType.title}
        </h1>
        <p className='text-sm text-neutral-400 z-10'>
          From{' '}
          <span className=''>
            £{getLowestPrice(serviceType?.serviceOptions) || '0.50'}
          </span>
        </p>
      </div>
      <div className='pointer-events-none absolute bottom-0 flex w-full translate-y-4 transform-gpu flex-row items-center p-4 opacity-0 transition-all duration-300 group-hover:translate-y-0 group-hover:opacity-100 z-10'>
        <p className='cursor-pointer px-2 text-accent text-sm text-right w-full'>
          Shop Now →
        </p>
      </div>
      <div className='pointer-events-none absolute inset-0 transform-gpu transition-all duration-300 group-hover:bg-black/[.03] group-hover:dark:bg-neutral-800/10' />
    </Link>
  );
};

export default DesktopServiceCard;
