const JumpsuitSvg = () => {
	return (
		<g>
			<path d='m26.05.76l-11.9,17.51H4.53s-1.53-3.96-2.13-8.69c-.04-.33.18-.63.51-.68,2.67-.41,13.87-2.46,17.48-8.14h5.66Z' />
			<polyline points='4.53 18.26 4.53 21.08 23.6 21.08 23.6 18.26' />
			<path d='m4.53,21.08h0C1.49,28.09.26,35.74.94,43.35l.84,9.38c.08.87.81,1.53,1.68,1.53h6.69c.93,0,1.68-.75,1.68-1.68.01-4.3.47-16.59,2.08-22.31' />
			<path d='m23.64,21.08h0c3.04,7.01,4.27,14.67,3.59,22.27l-.84,9.38c-.08.87-.81,1.53-1.68,1.53h-6.69c-.93,0-1.68-.75-1.68-1.68-.01-4.3-.8-16.59-2.41-22.31' />
			<path d='m9.07,21.08s-1.84,1.9-1.73,8.11' />
			<path d='m19.33,21.08s1.84,1.9,1.73,8.11' />
			<path d='m21.6,7.36s.67,1.35,4.45,2.16c.83.18-1.04,6.72-2.45,8.74h-9.44' />
			<path d='m15,5.21c-3.45-.5-5.26-4.45-5.26-4.45H3.94s2.29,3.1,4.76,6.87' />
		</g>
	);
};

export default JumpsuitSvg;
