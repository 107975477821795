import { UsersOrderData } from '@/types';

type StatusKey = keyof typeof statusMapper;

const statusMapper = {
  cancelled: 'bg-error',
  delivered: 'bg-success',
  collected: 'bg-blue-500',
  confirmed: 'bg-warning',
};

const getStatusBadgeVariant = (order: UsersOrderData): string | undefined => {
  for (const key of Object.keys(statusMapper) as StatusKey[]) {
    if (order[key]) {
      return statusMapper[key];
    }
  }
  return undefined;
};

export default getStatusBadgeVariant;
