import { useEffect, useState } from 'react';
import { useStateContext } from '../../context/ContextProvider';
import { getAllDeliveryMethods } from '../../utils/api';
import { DeliveryMethod } from '../../types';

const DeliveryMethodSelection = ({
  setDeliveryMethodId,
  deliveryMethodId,
}: {
  setDeliveryMethodId: (id: number) => void;
  deliveryMethodId: number;
}) => {
  const { setIsLoading } = useStateContext()!;
  const [deliveryMethods, setDeliveryMethods] = useState<DeliveryMethod[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const deliveryMethods = await getAllDeliveryMethods();
        setDeliveryMethods(deliveryMethods);

        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className='mt-6'>
      <h1 className='font-bold text-xl'>{`We'll return to you...`}</h1>

      <div className='w-full flex justify-center items-center gap-4 mb-6 mt-2'>
        {deliveryMethods.map((delivery) => (
          <div
            key={delivery.id}
            className={`card px-4 py-2 w-full h-20 flex justify-center items-center text-lg font-bold cursor-pointer ${
              deliveryMethodId === delivery.id
                ? 'bg-primary text-white'
                : 'bg-base-100'
            }`}
            onClick={() => setDeliveryMethodId(delivery.id)}
          >
            {delivery.longCode}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DeliveryMethodSelection;
