const ChildrensClothesSvg = () => {
	return (
		<g>
			<path d='m14.02.68c4.86,6.29,14.45,5.58,18.1,0,0,0,8.56,1.54,12.62,6.59.34.43.21,1.06-.28,1.31l-7.84,3.98c-.7.35-1.13,1.07-1.13,1.85v4c0,.46.19.9.52,1.22,1.98,1.9,8.97,9.42,9.34,21.29.03.98-.56,1.87-1.46,2.22-4.91,1.92-21.57,7.19-41.67-.02-.99-.35-1.61-1.33-1.52-2.37.33-3.87,1.93-13.38,9.56-21.24.3-.31.47-.73.47-1.16v-3.99c0-.78-.44-1.5-1.14-1.85L1.6,8.5c-.52-.26-.64-.96-.22-1.37C2.87,5.64,6.66,2.57,14.02.68Z' />
			<path d='m13.48,23.92s-5.35,6.35-4.91,21.1' />
			<path d='m20.31,25s-3.29,6.72-3.73,21.16' />
			<path d='m26.6,25s3.29,6.72,3.73,21.16' />
			<path d='m33.3,23.78s5.34,6.36,4.9,21.11' />
			<path d='m10.56,1.74s12.17,14.95,24.55-.28' />
			<path d='m21.09,17.82c.23,2.85-4.64,3.7-4.64,3.7-2.29-4.19,0-7.39,0-7.39,0,0,4.41.95,4.64,3.69Z' />
			<path d='m25.03,17.82c-.23,2.85,4.64,3.7,4.64,3.7,2.29-4.19,0-7.39,0-7.39,0,0-4.41.95-4.64,3.69Z' />
			<circle cx={23.03} cy={17.85} r={1.86} />
			<line x1={10.72} y1={15.39} x2={15.83} y2={15.39} />
			<line x1={30.32} y1={15.39} x2={35.43} y2={15.39} />
			<line x1={10.56} y1={19.04} x2={15.55} y2={19.04} />
			<line x1={30.59} y1={19.04} x2={35.58} y2={19.04} />
		</g>
	);
};

export default ChildrensClothesSvg;
