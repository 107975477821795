import CheckoutSteps from '@/components/Checkout/CheckoutSteps';
import OrderSummaryItems from '../components/Checkout/OrderSummaryItems';
import OrderSummaryPrices from '../components/Checkout/OrderSummaryPrices';

const Checkout = () => {
  return (
    <div className='w-full'>
      <CheckoutSteps />

      <div className='grid grid-cols-1 md:grid-cols-2 sm:px-2 w-full lg:px-10 sm:gap-10'>
        <OrderSummaryItems />
        <OrderSummaryPrices />
      </div>
    </div>
  );
};

export default Checkout;
