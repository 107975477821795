import emailjs, { EmailJSResponseStatus } from '@emailjs/browser';
import { LoggedInUser, UsersOrderData } from '../types';
import { formatDateToDDMMYYYY } from './formatDateToMMDDYYYY';

export const handleSendOrderConfirmationEmail = async (
	order: UsersOrderData,
	user: LoggedInUser
) => {
	if (order) {
		const formattedDate = formatDateToDDMMYYYY(new Date(order.collectionDate));

		const data = {
			orderId: order.id,
			createdAt: order.createdAt,
			collectionDate: formattedDate,
			notes: order.notes,
			fullName: user.fullName,
			email: user.email,
		};

		try {
			emailjs
				.send(
					import.meta.env.VITE_EMAIL_JS_SMTP_SERVICE_ID,
					import.meta.env.VITE_EMAIL_JS_ORDER_CONFIRMATION_TEMPLATE_ID,
					data,
					import.meta.env.VITE_EMAIL_JS_PUBLIC_KEY
				)
				.then((res: EmailJSResponseStatus) => {
					if (res.status === 200)
						console.log(
							'%chandleSendOrderConfirmationEmail.ts line:35 SUCCESSFULLY SENT EMAIL',
							'color: #26bfa5;'
						);
				})
				.catch((err: EmailJSResponseStatus) =>
					console.log('%cSendButton.tsx line:47 err', 'color: #26bfa5;', err)
				);
		} catch (error) {
			console.log(
				'%chandleSendOrderConfirmationEmail.ts line:23 ERROR: ',
				'color: #26bfa5;',
				error
			);
		}
	}
};
