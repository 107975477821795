const BeddingSingleSvg = () => {
	return (
		<g>
			<rect x={0.69} y={0.69} width={24.8} height={49.75} rx={4.09} ry={4.09} />
			<path d='m19.46,3.94l1.41-.36c.48-.12.92.3.82.79l-.27,1.28.03.17c.44,2.24.42,4.54-.04,6.77h0s.2,1.57.2,1.57c.06.44-.33.82-.77.75l-1.36-.22c-6.46,2.36-12.88,0-12.88,0l-1.38.11c-.44.03-.79-.35-.71-.78l.24-1.42h0c-.51-2.25-.55-4.58-.13-6.84l.02-.1-.31-1.18c-.13-.48.3-.93.78-.83l1.49.31s6.42-2.36,12.88,0' />
			<path d='m.69,17.95l24.8,7.97-5.21,4.75c-.48.44-1.19.48-1.72.1L.69,17.95Z' />
		</g>
	);
};

export default BeddingSingleSvg;
