import { useState } from 'react';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from '@/components/ui/drawer';
import useDeviceDetection from '@/hooks/useDeviceDetection';

const SizeGuideContent = () => {
  return (
    <div className='flex flex-col justify-center items-start px-10'>
      <div>
        <h3 className='text-lg font-semibold text-gray-700'>6kg</h3>
        <ul>
          <li>👕 12 T-shirts</li>
          <li>👖 2 Trousers</li>
          <li>🩳 7 Underwear</li>
        </ul>
      </div>
      <div className='divider'></div>
      <div>
        <h3 className='text-lg font-semibold text-gray-700'>9kg</h3>
        <ul>
          <li>👕 16 T-shirts</li>
          <li>👖 3 Trousers</li>
          <li>🧥 1 Jumper</li>
          <li>🩳 10 Underwear</li>
        </ul>
      </div>
    </div>
  );
};

const WashLoadSizeExampleModal = () => {
  const [open, setOpen] = useState(false);
  const device = useDeviceDetection();

  return device === 'Desktop' ? (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button className='btn btn-primary flex justify-center items-center gap-2'>
          <span className='text-xl'>&#9432;</span> Size Guide
        </Button>
      </DialogTrigger>
      <DialogContent className='bg-white'>
        <DialogHeader className='text-left'>
          <DialogTitle className='text-gray-700'>Size Guide</DialogTitle>
          <DialogDescription className='text-gray-500'>
            Our guidelines for how many clothes you can provide us for each size
            wash.
          </DialogDescription>
        </DialogHeader>

        <SizeGuideContent />
      </DialogContent>
    </Dialog>
  ) : (
    <Drawer open={open} onOpenChange={setOpen}>
      <DrawerTrigger asChild>
        <Button className='btn btn-primary flex justify-center items-center gap-2'>
          <span className='text-xl'>&#9432;</span> Size Guide
        </Button>
      </DrawerTrigger>
      <DrawerContent className='bg-white'>
        <DrawerHeader className='text-left'>
          <DrawerTitle className='text-gray-700'>Size Guide</DrawerTitle>
          <DrawerDescription className='text-gray-500'>
            Our guidelines for how many clothes you should provide us
          </DrawerDescription>
        </DrawerHeader>

        <SizeGuideContent />

        <DrawerFooter className='pt-2'>
          <DrawerClose asChild>
            <Button variant='outline'>Done</Button>
          </DrawerClose>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default WashLoadSizeExampleModal;
