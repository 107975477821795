import { BasketItem, Item, ServiceOption, ServiceType } from '../types';
import { toastNotification } from './toastNotification';

export const addToBasket = (
  basket: BasketItem[],
  setBasket: React.Dispatch<React.SetStateAction<BasketItem[]>>,
  itemToAdd: Item | ServiceOption,
  serviceTypesData: ServiceType[],
  setShowTickAnimation?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const updatedBasket = [...basket];
  const existingItemIndex = updatedBasket.findIndex(
    (basketItem) => basketItem.shortCode === itemToAdd.shortCode
  );

  if (existingItemIndex !== -1) {
    updatedBasket[existingItemIndex].quantity += 1;
  } else {
    const serviceType = serviceTypesData.find(
      (type) => type.id === itemToAdd.serviceTypeId
    );

    if (serviceType) {
      const newBasketItem: BasketItem = {
        ...itemToAdd,
        quantity: 1,
        serviceType,
      };

      updatedBasket.push(newBasketItem);
    }
  }

  toastNotification(
    `${itemToAdd.title} added to basket!`,
    'bg-success text-base-100'
  );

  setBasket(updatedBasket);

  if (setShowTickAnimation) {
    setShowTickAnimation(true);
    setTimeout(() => {
      setShowTickAnimation(false);
    }, 2000);
  }
};
