const ApronSvg = () => {
	return (
		<g>
			<path d='m16.49,8.98h8.16c1.2,0,2.19.92,2.28,2.12.22,2.82,1.24,7.73,5.58,10.17.72.4,1.16,1.16,1.16,1.99v24.69c0,1.26-1.02,2.28-2.28,2.28H9.86c-1.26,0-2.28-1.02-2.28-2.28v-24.91c0-.84.46-1.62,1.2-2.01,1.77-.93,4.92-3.47,5.45-9.94.1-1.18,1.09-2.09,2.27-2.09Z' />
			<path d='m14.22,11.08c-.17-6.54,1.63-10.42,6.34-10.37,4.79-.04,6.58,3.86,6.37,10.4' />
			<path d='m13.6,24.3h13.76v5.91c0,1.8-.89,3.48-2.38,4.48l-.88.59c-2.11,1.43-4.87,1.45-7.01.06l-1.03-.67c-1.54-1-2.46-2.71-2.46-4.54v-5.84Z' />
			<path d='m7.81,22.01l-.12-.04c-4.1.8-6.99,4.45-6.99,8.63v11.61' />
			<path d='m33.5,22.01l.12-.04c4.1.8,6.99,4.45,6.99,8.63v11.61' />
		</g>
	);
};

export default ApronSvg;
