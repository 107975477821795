const TrousersSvg = () => {
	return (
		<g>
			<rect x={4.78} y={0.81} width={25.41} height={6.82} rx={1.61} ry={1.61} />
			<line x1={30.19} y1={25.45} x2={30.19} y2={7.63} />
			<path d='m4.78,7.63l-3.36,11.48c-.87,2.97-.82,6.13.12,9.08l5.72,17.74c.63,1.96,2.46,3.29,4.52,3.29h18.4l14.2-27.24c.54-1.03.15-2.31-.87-2.87l-6.34-3.75c-.82-.49-1.88-.29-2.47.47l-4.52,5.82' />
			<line x1={30.19} y1={25.45} x2={30.28} y2={36.52} />
			<path d='m9.51,16.54h12.35v5.94c0,1.3-.69,2.5-1.82,3.14l-2.63,1.51c-1.06.61-2.37.6-3.43-.02l-2.69-1.59c-1.1-.65-1.78-1.84-1.78-3.12v-5.86Z' />
		</g>
	);
};

export default TrousersSvg;
