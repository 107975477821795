import { Link } from 'react-router-dom';
import { aboutUsPageContent } from '../data/aboutUsPageContent';
import { Helmet } from 'react-helmet';
import { useStateContext } from '../context/ContextProvider';

const AboutUs = () => {
  const { loggedInUser } = useStateContext()!;

  return (
    <div className='flex flex-col justify-center items-center w-full'>
      <Helmet>
        <meta charSet='utf-8' />
        <title>About Us | Laundro</title>
        <meta
          name='description'
          content='A super friendly team who are dedicated to saving you time and energy by taking care of all your laundry needs.'
        />
        <link rel='canonical' href='https://laundro.co.uk/about' />
      </Helmet>

      <h1 className='text-5xl font-bold text-center mb-6'>About Us</h1>

      <div className='grid grid-cols-1 sm:grid-cols-2 gap-10'>
        {aboutUsPageContent.slice(0, 2).map((content) => (
          <div key={content.title} className={`card bg-base-200 px-6 py-4`}>
            <h1 className='text-3xl font-bold'>{content.title}</h1>

            <p>{content.description}</p>
          </div>
        ))}
      </div>

      <div className='divider py-10'></div>

      <div className='bg-base-200 px-6 py-10 w-full card'>
        <h1 className='text-5xl font-bold text-center mb-6'>Why Us?</h1>

        {/* STATS */}
        <div className='stats stats-vertical md:stats-horizontal shadow mb-4 w-full bg-primary text-white'>
          <div className='stat'>
            <div className='stat-figure text-secondary'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                className='inline-block w-8 h-8 stroke-current text-white'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                ></path>
              </svg>
            </div>
            <div className='stat-title text-white'>{`Hours Saved`}</div>
            <div className='stat-value'>2,088</div>
          </div>
          <div className='stat'>
            <div className='stat-figure text-secondary'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                className='inline-block w-8 h-8 stroke-current text-white'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4'
                ></path>
              </svg>
            </div>
            <div className='stat-title text-white'>Laundrers Using Laundro</div>
            <div className='stat-value'>58</div>
          </div>
          <div className='stat'>
            <div className='stat-figure text-secondary'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                className='inline-block w-8 h-8 stroke-current text-white'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4'
                ></path>
              </svg>
            </div>
            <div className='stat-title text-white'>
              Collections and Deliveries
            </div>
            <div className='stat-value'>174</div>
          </div>
        </div>
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10'>
          {aboutUsPageContent.slice(2, 5).map((content) => (
            <div
              key={content.title}
              className={`card bg-base-200 px-6 py-4 ${content?.style}`}
            >
              <h1 className='text-3xl font-bold'>{content.title}</h1>
              <p>{content.description}</p>
            </div>
          ))}
        </div>
      </div>

      <div className='divider py-10'></div>

      <div className='card grid grid-cols-1 gap-10 bg-base-200 px-6 py-4'>
        {aboutUsPageContent.slice(5, 8).map((content) => (
          <div key={content.title} className={``}>
            <h1 className='text-3xl font-bold'>{content.title}</h1>

            <p>{content.description}</p>
          </div>
        ))}

        {!loggedInUser ? (
          <Link to={'/login'} className='btn btn-primary'>
            Register Now!
          </Link>
        ) : null}
      </div>
    </div>
  );
};

export default AboutUs;
