import { useState } from 'react';
import { toastNotification } from '../../utils/toastNotification';
import {
	checkPasswordStrength,
	validatePassword,
} from '../../utils/validatePassword';
import PasswordHider from '../PasswordHider';
import axios, { AxiosError } from 'axios';
import validator from 'validator';
import { registerUser } from '../../utils/api';
import { useNavigate } from 'react-router';
import PasswordValidationCriteria from './PasswordValidationCriteria';
import { useStateContext } from '../../context/ContextProvider';

class InvalidEmailError extends Error {
	constructor(message: string) {
		super(message);
		this.name = 'InvalidEmailError';
	}
}

interface RegisterProps {
	isPasswordVisible: boolean;
	setUserPassword: React.Dispatch<React.SetStateAction<string>>;
	setIsPasswordVisible: React.Dispatch<React.SetStateAction<boolean>>;
	userEmail: string;
	setUserEmail: React.Dispatch<React.SetStateAction<string>>;
	userPassword: string;
}

const Register = ({
	isPasswordVisible,
	setUserPassword,
	setIsPasswordVisible,
	userEmail,
	userPassword,
	setUserEmail,
}: RegisterProps) => {
	const { setLoggedInUser } = useStateContext()!;

	const [userFullName, setUserFullName] = useState('');
	const [userPhoneNumber, setUserPhoneNumber] = useState('');
	const [userConfirmedPassword, setUserConfirmedPassword] = useState('');

	const [passwordStrength, setPasswordStrength] = useState({
		length: false,
		number: false,
		uppercase: false,
	});

	const passwordsMatch = userPassword === userConfirmedPassword;

	const navigate = useNavigate();

	const handleRegisterUser = async () => {
		try {
			if (userFullName && userEmail && userPassword && userConfirmedPassword) {
				const passwordValidationResult = validatePassword(userPassword);

				// PASSWORD VALIDATION ERROR
				if (passwordValidationResult) {
					toastNotification(passwordValidationResult, 'bg-error text-base-100');
					return;
				}

				// PASSWORD MATCH ERROR
				if (passwordsMatch) {
					if (!validator.isEmail(userEmail)) {
						toastNotification(
							'Invalid email address',
							'bg-error text-base-100'
						);
						return;
					}

					if (
						userPhoneNumber.trim() !== '' &&
						!validator.isMobilePhone(userPhoneNumber, ['en-GB'])
					) {
						toastNotification('Invalid phone number', 'bg-error text-base-100');
						return;
					}

					const newUser = await registerUser(
						userFullName,
						userPhoneNumber,
						userEmail.toLocaleLowerCase().trim(),
						userPassword
					);

					localStorage.setItem('loggedInUserInfo', JSON.stringify(newUser));

					const storedUserInfo = localStorage.getItem('loggedInUserInfo')!;
					setLoggedInUser(JSON.parse(storedUserInfo));

					navigate('/profile');
					window.location.reload();
				} else {
					toastNotification('Passwords do not match', 'bg-error text-base-100');
				}
			} else {
				toastNotification(
					'Please fill out all fields',
					'bg-error text-base-100'
				);
			}
		} catch (error) {
			if (axios.isAxiosError(error)) {
				const axiosError = error as AxiosError;

				if (axiosError?.response?.data === 'Email already taken') {
					toastNotification(
						axiosError?.response?.data.toString(),
						'bg-error text-base-100'
					);
				}
			} else if (error instanceof InvalidEmailError) {
				toastNotification('Invalid email address', 'bg-error text-base-100');
			} else if (!userFullName) {
				toastNotification(
					'Please enter both a first name and a last name',
					'bg-error text-base-100'
				);
			} else if (!userPassword) {
				toastNotification('Please enter a password', 'bg-error text-base-100');
			}
		}
	};

	return (
		<div className='card flex-shrink-0 w-full max-w-md shadow-2xl bg-base-100'>
			<div className='card-body'>
				{/* FULL NAME */}
				<div className='form-control'>
					<label className='label'>
						<span className='label-text'>Full Name *</span>
					</label>
					<input
						type='text'
						placeholder='Terry Tibbs'
						className='input input-bordered placeholder:opacity-40'
						onChange={(e) => setUserFullName(e.target.value)}
						required
					/>
				</div>

				{/* EMAIL */}
				<div className='form-control'>
					<label className='label'>
						<span className='label-text'>Email *</span>
					</label>
					<input
						type='email'
						placeholder='terrytibbs@gmail.com'
						className='input input-bordered placeholder:opacity-40'
						onChange={(e) => setUserEmail(e.target.value)}
						required
					/>
				</div>

				{/* PHONE NUMBER */}
				<div className='form-control'>
					<label className='label'>
						<span className='label-text'>{`Phone Number (optional)`}</span>
					</label>
					<input
						type='text'
						placeholder='07010101010'
						className='input input-bordered placeholder:opacity-40'
						onChange={(e) => setUserPhoneNumber(e.target.value)}
					/>
				</div>

				{/* PASSWORD */}
				<div className='form-control flex-2'>
					<label className='label'>
						<span className='label-text'>Password *</span>
					</label>
					<div className='flex'>
						<input
							type={isPasswordVisible ? 'text' : 'password'}
							placeholder='password'
							className='input input-bordered flex-1 placeholder:opacity-40'
							// onChange={(e) => setUserPassword(e.target.value)}
							onChange={(e) => {
								setUserPassword(e.target.value);
								// Update password strength indicator
								const strengthInfo = checkPasswordStrength(e.target.value);
								setPasswordStrength(strengthInfo);
							}}
							required
						/>
						<PasswordHider
							isPasswordVisible={isPasswordVisible}
							setIsPasswordVisible={setIsPasswordVisible}
						/>
					</div>
				</div>

				{/* CONFIRM PASSWORD */}
				<div className='form-control'>
					<label className='label'>
						<span className='label-text'>Confirm Password *</span>
					</label>
					<div className='flex'>
						<input
							type={isPasswordVisible ? 'text' : 'password'}
							placeholder='retype password'
							className='input input-bordered flex-1 placeholder:opacity-40'
							onChange={(e) => setUserConfirmedPassword(e.target.value)}
							required
						/>
						<PasswordHider
							isPasswordVisible={isPasswordVisible}
							setIsPasswordVisible={setIsPasswordVisible}
						/>
					</div>

					<PasswordValidationCriteria
						password={userPassword}
						passwordStrength={passwordStrength}
					/>
				</div>
				<div className='form-control mt-6'>
					<button className='btn btn-primary' onClick={handleRegisterUser}>
						Register
					</button>
				</div>
			</div>
		</div>
	);
};

export default Register;
