const TeaTowelSvg = () => {
	return (
		<g>
			<path d='m6.78,45.2l.12,4.29c.01.44.41.77.85.7l5.21-.85c.98-.16,1.11-1.52.17-1.86l-11.75-4.21c-.5-.18-.79-.71-.67-1.23L9.99,2.48c.25-1.06,1.19-1.8,2.27-1.8h2.66c1.05,0,2,.65,2.37,1.64l12.58,33.4c.16.42-.1.88-.54.96l-19.86,3.55c-.9.16-1.04,1.4-.19,1.75l15.74,6.51c.51.21,1.06-.17,1.06-.72l-.15-10.49' />
			<path d='m12.15,6.27h0c.88,1.45,1.23,3.15.98,4.83l-4.42,29.94' />
			<line x1={9.34} y1={36.8} x2={29} y2={33.4} />
			<line x1={11.64} y1={39.92} x2={26.09} y2={45.19} />
			<line x1={13.74} y1={48.6} x2={13.74} y2={43.82} />
			<line x1={1.43} y1={39.45} x2={13.68} y2={44} />
			<line x1={6.83} y1={47.21} x2={10.96} y2={46.7} />
		</g>
	);
};

export default TeaTowelSvg;
