const PoloShirtSvg = () => {
	return (
		<g>
			<path d='m8.18,7.47s3.51,2.5,3.76,9.77c.21,6.19.06,23.99.01,29.12,0,.77.62,1.4,1.39,1.4h21.39c.76,0,1.38-.61,1.39-1.38l.23-27.78c.02-2.44.4-4.88,1.2-7.18.58-1.67,1.41-3.38,2.58-4.49l-8.64-2.78-1.82-2.61c-.29-.41-.76-.66-1.26-.66-2.88.64-5.67.68-8.36,0-.48,0-.94.23-1.23.61l-2.01,2.66-8.64,3.33Z' />
			<path d='m8.18,7.47L1.19,17.79c-.51.76-.39,1.78.3,2.39l4.43,3.96c.76.68,1.93.61,2.6-.17l3.48-4.01' />
			<path d='m40.13,6.93l7.13,10.88c.5.76.37,1.76-.31,2.36l-4.37,3.94c-.77.69-1.97.61-2.63-.19l-3.61-4.34' />
			<path d='m18.83,1.49l1.1,1.85c1.09,1.82,2.52,3.41,4.22,4.67h0s0,0,0,0c1.76-1.43,3.19-3.23,4.17-5.27l.89-1.86' />
			<line x1={24.16} y1={8.01} x2={24.16} y2={15.68} />
			<path d='m26.39,5.79l3.2,3.31c1.02-1.51,1.6-3.18,1.9-4.95' />
			<path d='m21.73,5.79l-3.2,3.31c-1.02-1.51-1.6-3.18-1.9-4.95' />
		</g>
	);
};
export default PoloShirtSvg;
