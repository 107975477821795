import { useStateContext } from '../../context/ContextProvider';
import NavigationPills from './NavigationPills';
import Tabs from '../Tabs';
import { Helmet } from 'react-helmet';
import WashLoadSizeExampleModal from '../WashLoadSizeExampleModal';

const ServicePage = ({ service }: { service: string }) => {
  const { serviceTypesData } = useStateContext()!;

  const selectedService = serviceTypesData.find(
    (serviceType) => serviceType.shortCode === service
  );

  const services = ['ironing', 'duvets'];

  return (
    <div className='flex flex-col gap-2 w-full sm:max-w-[80%] overflow-x-hidden min-h-3/4'>
      <Helmet>
        <meta charSet='utf-8' />
        <title>{`${service.split('-').join(' ')} | Laundro`}</title>
        <link href={`https://laundro.co.uk/laundry/services/${service}`} />
      </Helmet>

      <NavigationPills />

      <div className='card bg-base-200 p-6'>
        <h1 className='text-2xl font-bold mt-4'>{selectedService?.title}</h1>
        <p className='text-base mb-4'>{selectedService?.description}</p>

        {!services.includes(selectedService?.shortCode || '') ? (
          <WashLoadSizeExampleModal />
        ) : null}

        <Tabs service={service} />
      </div>
    </div>
  );
};

export default ServicePage;
