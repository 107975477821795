const SkirtSvg = () => {
	return (
		<g>
			<rect x={6.42} y={0.74} width={27.41} height={4.8} rx={1.22} ry={1.22} />
			<path d='m34.32,49.48h3.35c.95,0,1.73-.75,1.77-1.71.95-27.25-5.59-42.24-5.59-42.24' />
			<path d='m6.42,5.53S-.12,20.53.83,47.78c.03.95.82,1.71,1.77,1.71h26.28' />
			<polyline points='34.32 49.48 31.36 27.57 28.89 49.48' />
		</g>
	);
};

export default SkirtSvg;
