export const validatePassword = (password: string) => {
	// Password should be at least 8 characters
	if (password.length < 8) {
		return 'Password should be at least 8 characters';
	}

	// Password should contain at least one number
	if (!/\d/.test(password)) {
		return 'Password should contain at least one number';
	}

	// Password should contain at least one uppercase letter
	if (!/[A-Z]/.test(password)) {
		return 'Password should contain at least one uppercase letter';
	}

	return null; // Password meets all criteria
};

export const checkPasswordStrength = (password: string) => {
	const strengthInfo = {
		length: password.length >= 8,
		number: /\d/.test(password),
		uppercase: /[A-Z]/.test(password),
	};

	return strengthInfo;
};
