import { useEffect } from 'react';
import { useStateContext } from '../context/ContextProvider';
import { UsersOrderData } from '../types';
import {
  refundOrderByChargeId,
  getOrdersByUserId,
  updateOrderById,
} from '../utils/api';
// import { addToBasket } from '../utils/addToBasket';
import { Helmet } from 'react-helmet';

const OrdersAndSubscriptions = () => {
  const {
    usersOrderData,
    setUsersOrderData,
    loggedInUser,
    loggedInUserToken,
    // basket,
    // setBasket,
    // serviceTypesData,
  } = useStateContext()!;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const orders: UsersOrderData[] = await getOrdersByUserId(
          loggedInUser.id,
          loggedInUserToken
        );

        if (!orders) return;

        orders.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );

        setUsersOrderData(orders);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const isOrderCancelable = (order: UsersOrderData) => {
    const currentDateTime = new Date();
    const collectionDateTime = new Date(order.collectionDate);

    // Calculate the date one day before the collection date
    const oneDayBeforeCollectionDate = new Date(collectionDateTime);
    oneDayBeforeCollectionDate.setDate(
      oneDayBeforeCollectionDate.getDate() - 1
    );

    return currentDateTime < oneDayBeforeCollectionDate && !order.cancelled;
  };

  const handleCancelOrder = async (orderId: number, latestChargeId: string) => {
    try {
      const refundedOrder = await refundOrderByChargeId(
        latestChargeId,
        loggedInUserToken,
        loggedInUser
      );
      const updatedOrder = await updateOrderById(
        orderId,
        { cancelled: true },
        loggedInUserToken
      );

      if (updatedOrder && refundedOrder) {
        setUsersOrderData((prevOrders) =>
          prevOrders.filter((order) => order.id !== updatedOrder.id)
        );
        window.location.reload();
      }
    } catch (error) {
      console.log(
        '%cOrdersAndSubscriptions.tsx line:34 error',
        'color: #26bfa5;',
        error
      );
    }
  };

  return (
    <div className='prose w-full'>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Your Orders | Laundro</title>
        <meta
          name='description'
          content={`You can find all of your previous and current orders here! As well as manage any subscriptions you have. You can easily order a previous order again too!`}
        />
        <link
          rel='canonical'
          href='https://laundro.co.uk/orders-and-subscriptions'
        />
      </Helmet>

      {usersOrderData.length !== 0 ? (
        <div className='flex flex-col gap-10'>
          <h1 className='text-center'>Your Orders 🎉</h1>
          {usersOrderData
            .filter((cancelled) => !cancelled.cancelled)
            .map((order: UsersOrderData) => {
              return (
                <div
                  key={order.id}
                  className='card bg-base-200 px-4 md:px-10 py-2'
                >
                  <div>
                    <h3>
                      Ordered On:{' '}
                      {new Date(order.createdAt).toLocaleDateString()}
                    </h3>
                    <h3>
                      Collection Date:
                      {new Date(order.collectionDate).toLocaleDateString()}
                    </h3>
                  </div>

                  <div className='overflow-x-auto'>
                    <table className='table '>
                      <thead>
                        <tr>
                          <th>Item</th>
                          <th>Quantity</th>
                          <th>Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {order.basketItems.map((item) => (
                          <tr key={item.id}>
                            <td>{`${item.title}`}</td>
                            <td>{item.quantity}</td>
                            <td>£{(item.quantity * item.price).toFixed(2)}</td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td className='text-lg'>Sub Total:</td>
                          <td></td>
                          <td className='text-lg underline'>
                            £
                            {order.basketItems
                              .reduce(
                                (total, item) =>
                                  total + item.price * item.quantity,
                                0
                              )
                              .toFixed(2)}
                          </td>
                        </tr>

                        <tr>
                          <td className='text-lg'>Delivery Charge:</td>
                          <td></td>
                          <td className='text-lg font-semibold'>
                            £{order.deliveryMethod.price.toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <td className='text-lg'>Total:</td>
                          <td></td>
                          <td className='text-xl underline'>
                            £{order.totalPrice.toFixed(2)}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>

                  {order.notes !== '' ? (
                    <div>
                      <div className='divider'></div>
                      <p className='text-xl italic'>
                        <span className='font-bold'>Notes:</span> {order.notes}
                      </p>
                      <div className='divider'></div>
                    </div>
                  ) : null}

                  {isOrderCancelable(order) ? (
                    <button
                      className='btn btn-error'
                      onClick={() =>
                        handleCancelOrder(order.id, order.latestChargeId)
                      }
                    >
                      Cancel This Order
                    </button>
                  ) : null}

                  {/* create an 'order again' button which adds the contents of the currently selected order to the basket again */}
                  {/* <h3>Want to order again?</h3>
                  <button
                    className='btn btn-primary mb-4'
                    onClick={() =>
                      addToBasket(
                        basket,
                        setBasket,
                        order.basketItems,
                        serviceTypesData
                      )
                    }
                  >
                    Add to basket
                  </button> */}
                </div>
              );
            })}

          {/* CANCELLED ORDERS */}
          <h2 className='-mb-6'>Cancelled Orders</h2>
          {usersOrderData
            .filter((cancelled) => cancelled.cancelled)
            .map((order: UsersOrderData) => (
              <div
                key={order.id}
                className='card bg-base-200 px-4 md:px-10 py-2'
              >
                <div className='flex justify-center items-center gap-6'>
                  <h3 className=''>
                    Ordered On: {new Date(order.createdAt).toLocaleDateString()}
                  </h3>
                  <h3>
                    Collection Date:
                    {new Date(order.collectionDate).toLocaleDateString()}
                  </h3>
                </div>
                <div className='overflow-x-auto'>
                  <table className='table '>
                    <thead>
                      <tr>
                        <th>Item</th>
                        <th>Quantity</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {order.basketItems.map((item) => (
                        <tr key={item.id}>
                          <td>{`${item.title}`}</td>
                          <td>{item.quantity}</td>
                          <td>£{(item.quantity * item.price).toFixed(2)}</td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td className='text-lg'>Total:</td>
                        <td></td>
                        <td className='text-lg underline'>
                          £
                          {order.basketItems
                            .reduce(
                              (total, item) =>
                                total + item.price * item.quantity,
                              0
                            )
                            .toFixed(2)}
                        </td>
                      </tr>

                      <tr>
                        <td className='text-lg'>Delivery Charge:</td>
                        <td></td>
                        <td className='text-lg font-semibold'>
                          £{order.deliveryMethod.price.toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td className='text-lg'>Total:</td>
                        <td></td>
                        <td className='text-xl underline'>
                          £{order.totalPrice.toFixed(2)}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>

                {order.notes !== '' && (
                  <div>
                    <div className='divider'></div>
                    <p className='text-xl italic'>
                      <span className='font-bold'>Notes:</span> {order.notes}
                    </p>
                    <div className='divider'></div>
                  </div>
                )}
              </div>
            ))}
        </div>
      ) : (
        <div className='mt-10 h-screen'>
          <h1 className='text-lg font-semibold'>
            When you make an order, they will appear here!
          </h1>
        </div>
      )}
    </div>
  );
};

export default OrdersAndSubscriptions;
