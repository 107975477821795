const DressSvg = () => {
	return (
		<g>
			<path d='m20.97,13.98c-1.34-5.86-9.02-7.07-9.02-7.07-.38-5.92-1.91-6.11-1.91-6.11,0,0-3.97-1.53-9.23,15.03-.12.38.01.81.26,1.12l2.46,3.15c.35.44.95.6,1.46.38l6.42-2.69' />
			<g>
				<path d='m20.97,13.98c1.3-5.29,9.06-7.07,9.06-7.07.38-5.92,1.91-6.11,1.91-6.11,0,0,3.97-1.53,9.23,15.03.12.38-.01.81-.26,1.12l-2.46,3.15c-.35.44-.95.6-1.46.38l-6.42-2.69' />
				<path d='m11.38,12.45s-1.28,2.08,1.15,9.36c1.62,4.86-4.86,24.81-6.87,30.77-.28.83.34,1.69,1.22,1.69l10.18-.89c3.4-.3,6.81-.27,10.21.08l7.85.81c.88,0,1.5-.86,1.22-1.69-2.01-5.96-8.49-25.92-6.87-30.77,2.43-7.28,1.15-9.36,1.15-9.36' />
			</g>
		</g>
	);
};

export default DressSvg;
