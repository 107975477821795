import { Link } from 'react-router-dom';
import HeroSVG from '../assets/SVG/HeroSVG';
import Map from '../components/Map';
import Spline from '@splinetool/react-spline';

// HELMET
import { Helmet } from 'react-helmet';
import useDeviceDetection from '../hooks/useDeviceDetection';

const Home = () => {
  const device = useDeviceDetection();

  return (
    <div className='flex flex-col justify-center items-center -mt-20'>
      <Helmet>
        <meta charSet='utf-8' />
        <meta
          name='description'
          content='Laundro - Laundry to go. The online laundrette | We offer free collection and delivery of your laundry for Washing, Drying and Ironing services!'
        />
        <title>Laundry delivery | Online laundrette | Laundro</title>
        <link rel='canonical' href='https://laundro.co.uk/' />
      </Helmet>

      <div className='hero min-h-screen bg-base-100'>
        {device === 'Desktop' ? (
          <Spline
            scene='https://prod.spline.design/W-0CEypYXQWEqcQf/scene.splinecode'
            style={{ width: '100vw' }}
          />
        ) : null}

        <div className='hero-content flex-col lg:flex-row-reverse gap-20'>
          {device === 'Mobile' ? <HeroSVG /> : null}

          <div
            className={`flex flex-col gap-4 ${
              device === 'Desktop' && 'absolute left-52 px-6 w-[400px]'
            }`}
          >
            <h1 className='text-5xl font-bold'>Look Familiar?</h1>
            <h2 className='text-xl font-semibold text-gray-400'>
              {`Let us take care of it, so your home doesn't look like a `}
              <span className='text-accent italic'>laundrette!</span>
            </h2>
            <Link to={'/laundry/services'} className='btn btn-primary'>
              Help Me out!
            </Link>
          </div>
        </div>
      </div>

      <div className='p-4 sm:p-0 w-full'>
        <Map width='200%' />
      </div>
    </div>
  );
};

export default Home;
