const CardiganSvg = () => {
	return (
		<g>
			<path d='m13.1,27.45l-4.04,8.53c-.4.83-1.23,1.36-2.17,1.43h-3.8c-1.59-.07-2.74-1.62-2.27-3.15l7.61-24.83c.72-2.34,2.36-4.28,4.54-5.39l6.07-3.07c1.04-.53,2.31-.22,3,.71l5.86,7.45' />
			<path d='m42.47,27.51l3.84,8.53c.4.83,1.23,1.36,2.16,1.36h3.8c1.61,0,2.75-1.55,2.28-3.09l-7.61-24.83c-.72-2.34-2.36-4.28-4.54-5.39l-6.07-3.07c-1.04-.53-2.31-.22-3,.71l-5.45,7.38c-.3.41-.47.91-.47,1.42v16.14' />
			<path d='m43.41,18.7l-1.65,14.24c-.17,1.28-1.27,2.24-2.56,2.24,0,0-23.04,0-23.04,0-1.29,0-2.39-.96-2.56-2.24l-1.65-14.24' />
			<circle cx={30.11} cy={11.7} r={0.3} />
			<circle cx={30.12} cy={17.5} r={0.3} />
			<circle cx={30.12} cy={23.57} r={0.3} />
			<path d='m20.63.78s7.34,1.71,14.3.02' />
		</g>
	);
};

export default CardiganSvg;
