import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { handleSendOrderConfirmationEmail } from '../utils/handleSendOrderConfirmationEmail';
import { useStateContext } from '../context/ContextProvider';
import { createOrder, getPaymentIntentId } from '../utils/api';
import Confetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize';

const OrderConfirmation = () => {
	const { width, height } = useWindowSize();

	const {
		orderData,
		loggedInUser,
		deliveryMethodId,
		setBasket,
		loggedInUserToken,
		customerNotes,
		selectedDate,
	} = useStateContext()!;

	const navigate = useNavigate();

	const total = orderData?.basketItems?.reduce(
		(total, item) => total + item.price * item.quantity,
		0
	);

	useEffect(() => {
		const postOrder = async () => {
			try {
				const { paymentIntentId, latestChargeId } = await getPaymentIntentId(
					loggedInUser
				);

				const order = await createOrder(
					{
						...orderData,
						totalPrice: total,
						deliveryMethodId,
						paymentIntentId,
						latestChargeId,
					},
					loggedInUserToken,
					customerNotes,
					selectedDate
				);

				if (order) {
					handleSendOrderConfirmationEmail(order, loggedInUser);
					setBasket([]);
					localStorage.removeItem('customerNotes');
					localStorage.removeItem('deliveryMethodId');
					localStorage.removeItem('selectedDate');
					localStorage.removeItem('orderData');
				}

				setTimeout(() => {
					navigate('/orders-and-subscriptions');
				}, 5000);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		postOrder();
	}, []);

	return (
		<div className='w-screen flex justify-center items-center prose text-center'>
			<div className='p-10 rounded-lg flex flex-col justify-center items-center gap-4'>
				<span className='text-6xl sm:text-[120px]'>🧺 → 😍</span>
				<h1>Thanks for making an order!</h1>

				<div className='card bg-base-200 p-4 flex flex-col gap-4'>
					<h2>{`We're thrilled you've chosen us to take care of your laundry.`}</h2>
					<p className='-mt-6 text-xl'>
						{`We've got your order request! You should receive an email from us
						shortly - confirm your order by following the instructions in the
						email and make payment, within the time window specified.`}
					</p>
				</div>

				<p className='text-lg italic -mt-2'>
					If for some reason we are unable to fulfill your order we will contact
					you to let you know and we will attempt to reschedule for you.
				</p>

				<div className='flex gap-4'>
					<Link
						className='btn btn-primary no-underline text-white'
						to={'/profile'}
					>
						Visit Your Profile
					</Link>
					<Link className='btn btn-base-100 no-underline' to={'/'}>
						Go Home
					</Link>
				</div>
			</div>

			<Confetti
				width={width}
				height={height}
				colors={['#9FB8AD', '#440381', '#475841', '#FFA5A5', '#FA7921']}
			/>
		</div>
	);
};

export default OrderConfirmation;
