export const aboutUsPageContent = [
	{
		title: 'About Us',
		description: `Welcome to Laundro, where laundry day becomes a breeze! We are not just a laundry service; we are your solution to convenience and freshness, delivered right to your doorstep.`,
		// style: 'bg-primary',
	},
	{
		title: 'Our Story',
		description: `Our journey began with a simple idea: to transform the way people experience laundry. We recognised the daily struggle of balancing work, family, and social commitments, leaving little time for the laundry pile that never seemed to shrink. That's when we decided to step in and make a difference.`,
		// style: 'bg-secondary',
	},
	{
		title: 'Our Mission',
		description: `At Laundro, our mission is clear: to give you back the gift of time. We believe in simplifying your life by taking the hassle out of laundry. No more sorting, washing, or folding – just leave it to us. We're not just about clean clothes; we're about a cleaner, more convenient lifestyle.`,
		style: 'bg-secondary text-white',
	},
	{
		title: 'What We Offer',
		description: `Our comprehensive mobile laundry service covers everything from washing and drying to ironing. We use state-of-the-art equipment and eco-friendly detergents to ensure your clothes look and feel their best. We handle your laundry with the same care and attention that you would.`,
		style: 'bg-secondary text-white',
	},
	{
		title: 'The 24-Hour Goal',
		description: `We understand that time is of the essence. That's why if you choose our Next Day service we aim to collect your laundry and have it back to you, fresh and clean, within 24 hours. It's our commitment to punctuality, so you can plan your day with confidence.`,
		style: 'bg-secondary text-white',
	},
	{
		title: 'Our Team',
		description: `Behind every great service is an even greater team. Our dedicated laundry experts are passionate about what they do, and they take pride in delivering exceptional results. We train our team to treat your clothes as if they were their own, ensuring that every item receives the utmost care.`,
		// style: 'bg-secondary text-white',
	},
	{
		title: 'Your Trusted Laundry Partner',
		description: `We are more than just a laundry service; we are your trusted laundry partner. Whether you're a busy professional, a parent on the go, or simply someone who values their time, we are here to make your life easier. Say goodbye to laundry day stress and hello to convenience, freshness, and free time!`,
		// style: 'bg-purple-300',
	},
	{
		title: 'Get Started',
		description: `Join the Laundro family today and experience the difference. Laundry day has never been this easy, and we can't wait to show you how we're changing the way you do laundry.

        Thank you for choosing us as your laundry solution. Your satisfaction is our ultimate reward.`,
		// style: 'bg-brown-300',
	},
];
