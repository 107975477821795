const BlazerSvg = () => {
	return (
		<g>
			<path d='m17.21.72h9.17c.92,0,1.81.37,2.46,1.03l2.46,2.52c.33.34.22.9-.21,1.09l-1.66.74,1.71,1.77c.49.5.56,1.28.17,1.86l-9.35,13.94-9.71-13.89c-.42-.6-.34-1.43.2-1.93l1.77-1.68-1.85-.95c-.42-.21-.49-.77-.15-1.09l2.64-2.48c.64-.6,1.48-.93,2.35-.93Z' />
			<path d='m14.05,2.41l-6.47,2.79c-1.88.81-3.18,2.58-3.39,4.62L.72,43.36c-.08.81.55,1.51,1.36,1.51h4.8l-.24,1.58c-.04.29.14.57.42.64,2.36.61,12.45,2.65,14.97-5.79,0,0,1.57,8.9,14.75,5.8.32-.07.51-.4.42-.71l-.42-1.51h4.98c.82,0,1.45-.71,1.36-1.52l-3.72-33.7c-.22-2.02-1.51-3.77-3.38-4.58l-6.86-2.98' />
			<path d='m6.88,44.87l2.2-6.99c1.9-6.03,1.98-12.48.23-18.56l-1.24-4.31' />
			<path d='m36.8,44.87l-2.25-6.99c-1.9-6.03-1.98-12.48-.23-18.56l1.24-4.31' />
			<polyline points='15.66 1.09 21.97 23.66 22.04 41.29' />
			<line x1={27.94} y1={1.09} x2={21.97} y2={23.66} />
			<line x1={9.98} y1={34.29} x2={14.05} y2={34.29} />
			<line x1={29.23} y1={34.29} x2={33.3} y2={34.29} />
			<circle cx={24.62} cy={26.83} r={0.5} />
			<circle cx={24.62} cy={32.77} r={0.5} />
		</g>
	);
};

export default BlazerSvg;
