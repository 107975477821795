import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  BasketItem,
  LoggedInUser,
  OrderData,
  ServiceType,
  UsersOrderData,
} from '../types';
import WasherSpinner from '../assets/SVG/WasherSpinner';
import { themeMappings } from '../utils/themeMapper';
import { getServiceTypesData } from '../utils/api';

interface MyContextState {
  serviceTypesData: ServiceType[];
  setServiceTypesData: Dispatch<SetStateAction<ServiceType[]>>;
  basket: BasketItem[];
  loggedInUser: LoggedInUser;
  setLoggedInUser: Dispatch<SetStateAction<LoggedInUser>>;
  loggedInUserToken: string;
  orderData: OrderData;
  setOrderData: Dispatch<SetStateAction<OrderData>>;
  usersOrderData: UsersOrderData[];
  setUsersOrderData: Dispatch<SetStateAction<UsersOrderData[]>>;
  theme: string;
  setTheme: Dispatch<SetStateAction<string>>;
  OTP: string;
  setOTP: Dispatch<SetStateAction<string>>;
  passwordResetEmail: string;
  setPasswordResetEmail: Dispatch<SetStateAction<string>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  customerNotes: string;
  setCustomerNotes: Dispatch<SetStateAction<string>>;
  deliveryMethodId: number;
  setDeliveryMethodId: Dispatch<SetStateAction<number>>;
  selectedDate: string;
  setSelectedDate: Dispatch<SetStateAction<string>>;
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

type SetServiceTypesData = Dispatch<SetStateAction<ServiceType[]>>;
type SetBasketItems = Dispatch<SetStateAction<BasketItem[]>>;
type SetLoggedInUser = Dispatch<SetStateAction<LoggedInUser>>;
type SetOrderData = Dispatch<SetStateAction<OrderData>>;
type SetUsersOrderData = Dispatch<SetStateAction<UsersOrderData[]>>;
type SetTheme = Dispatch<SetStateAction<string>>;
type SetOTP = Dispatch<SetStateAction<string>>;
type SetPasswordResetEmail = Dispatch<SetStateAction<string>>;
type SetIsLoading = Dispatch<SetStateAction<boolean>>;
type SetCustomerNotes = Dispatch<SetStateAction<string>>;
type SetDeliveryMethodId = Dispatch<SetStateAction<number>>;
type SetSelectedDate = Dispatch<SetStateAction<string>>;
type SetIsModalOpen = Dispatch<SetStateAction<boolean>>;

interface MyContextStateWithSetters extends MyContextState {
  setServiceTypesData: SetServiceTypesData;
  setBasket: SetBasketItems;
  setLoggedInUser: SetLoggedInUser;
  setOrderData: SetOrderData;
  setUsersOrderData: SetUsersOrderData;
  setTheme: SetTheme;
  setOTP: SetOTP;
  setPasswordResetEmail: SetPasswordResetEmail;
  setIsLoading: SetIsLoading;
  setCustomerNotes: SetCustomerNotes;
  setDeliveryMethodId: SetDeliveryMethodId;
  setSelectedDate: SetSelectedDate;
  setIsModalOpen: SetIsModalOpen;
}

const StateContext = createContext<MyContextStateWithSetters | null>(null);

export const ContextProvider = ({ children }: { children: ReactNode }) => {
  const [serviceTypesData, setServiceTypesData] = useState<ServiceType[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  // Load basket items from local storage on initial load
  const [basket, setBasket] = useState<BasketItem[]>(
    JSON.parse(localStorage.getItem('basket') || '[]')
  );

  const storedOrderData = localStorage.getItem('orderData');
  const [orderData, setOrderData] = useState<OrderData>(
    storedOrderData
      ? JSON.parse(storedOrderData)
      : { userId: '', basketItems: [] }
  );

  const [usersOrderData, setUsersOrderData] = useState<UsersOrderData[]>([]);

  const storedThemePreference = localStorage.getItem('themePreference');
  const [theme, setTheme] = useState(
    storedThemePreference && themeMappings[storedThemePreference]
      ? storedThemePreference
      : 'light'
  );

  const storedOTP = localStorage.getItem('OTP');
  const [OTP, setOTP] = useState(storedOTP ? JSON.parse(storedOTP) : '');

  const storedPasswordResetEmail = localStorage.getItem('passwordResetEmail');
  const [passwordResetEmail, setPasswordResetEmail] = useState(
    storedPasswordResetEmail ? JSON.parse(storedPasswordResetEmail) : ''
  );

  const storedUserInfo = localStorage.getItem('loggedInUserInfo');
  const [loggedInUser, setLoggedInUser] = useState(
    storedUserInfo ? JSON.parse(storedUserInfo).user : null
  );
  const [loggedInUserToken] = useState(
    storedUserInfo ? JSON.parse(storedUserInfo).token : null
  );

  const storedCustomerNotes = localStorage.getItem('customerNotes');
  const [customerNotes, setCustomerNotes] = useState(
    storedCustomerNotes ? JSON.parse(storedCustomerNotes) : ''
  );

  const storedDeliveryMethodId = localStorage.getItem('deliveryMethodId');
  const [deliveryMethodId, setDeliveryMethodId] = useState<number>(
    storedDeliveryMethodId ? JSON.parse(storedDeliveryMethodId) : 1
  );

  const storedSelectedDate = localStorage.getItem('selectedDate');
  const [selectedDate, setSelectedDate] = useState<string>(
    storedSelectedDate ? JSON.parse(storedSelectedDate) : ''
  );

  // MODAL STATE
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const serviceTypes = await getServiceTypesData();
        setServiceTypesData(serviceTypes);

        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    localStorage.setItem('basket', JSON.stringify(basket));
  }, [basket]);

  return (
    <StateContext.Provider
      value={{
        serviceTypesData,
        setServiceTypesData,
        basket,
        setBasket,
        loggedInUser,
        setLoggedInUser,
        loggedInUserToken,
        orderData,
        setOrderData,
        usersOrderData,
        setUsersOrderData,
        theme,
        setTheme,
        OTP,
        setOTP,
        passwordResetEmail,
        setPasswordResetEmail,
        setIsLoading,
        customerNotes,
        setCustomerNotes,
        deliveryMethodId,
        setDeliveryMethodId,
        selectedDate,
        setSelectedDate,
        isModalOpen,
        setIsModalOpen,
      }}
    >
      {isLoading ? (
        <div className='w-screen h-screen flex justify-center items-center'>
          <WasherSpinner />
        </div>
      ) : (
        children
      )}
    </StateContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useStateContext = () => useContext(StateContext);
