const TableclothSvg = () => {
	return (
		<g>
			<path d='m49.47,5.04C43.26-.33,13.03-1.15,3.87,4.86c-.75.49-.97,1.5-.44,2.22,4.42,6.04,47.16,5.39,46.59-1.04-.03-.39-.25-.74-.54-1Z' />
			<path d='m3.43,7.08s-.07,7.85-2.68,13.96c-.23.54.13,1.14.72,1.2l1.08.11c.86.09,1.65.48,2.24,1.09l1.1,1.14c.89.92,2.23,1.26,3.46.88l1.33-.42c1.19-.37,2.5-.12,3.45.68l.94.79c.95.8,2.31.95,3.42.37l1.04-.54c1.09-.57,2.4-.5,3.42.17l1.46.96c1.01.66,2.32.69,3.35.06l1.71-1.04c1.07-.65,2.41-.67,3.5-.05l.48.27c1.28.73,2.88.65,4.08-.22l.9-.65c1.21-.87,2.79-1.09,4.2-.57l.52.19c1.23.45,2.62.1,3.47-.87l.8-.91c.76-.86,1.86-1.37,3.02-1.38l1.39-.02c.6,0,1.02-.6.82-1.15-.87-2.4-2.71-8.34-2.62-15.07' />
			<path d='m8.23,9.48s1,3.81-1.5,10.46' />
			<path d='m21.28,11.14s.01,6.34-.87,11.4' />
			<path d='m31.99,11.25s-.44,5.08.91,11.29' />
			<path d='m44.77,9.48s-.47,3.95,1.56,10.46' />
			<path d='m20.85,25.96l-.43,7h-3.17c-.77-.02-1.39.59-1.39,1.34v.79c0,.75.62,1.36,1.38,1.36h18.63c.76,0,1.38-.61,1.38-1.36v-.78c0-.75-.62-1.36-1.38-1.36h-2.97l-.91-6.9' />
			<line x1={20.41} y1={32.97} x2={32.9} y2={32.97} />
		</g>
	);
};

export default TableclothSvg;
