import { useState } from 'react';
import { useStateContext } from '../context/ContextProvider';
import PriceList from './Services/PriceList';
import ServiceDetails from './Services/ServiceDetails';

const Tabs = ({ service }: { service: string }) => {
  const { serviceTypesData } = useStateContext()!;

  const [selectedTab, setSelectedTab] = useState('Price List');

  const selectedService = serviceTypesData.find(
    (serviceType) => serviceType.shortCode === service
  );

  const getTabClassName = (tabName: string) => {
    return `tab tab-bordered ${selectedTab === tabName ? 'tab-active' : ''}`;
  };

  const handleTabClick = (tabName: string) => {
    setSelectedTab(tabName);
  };

  return (
    <div className='mt-4'>
      <div className='tabs mb-4'>
        <button
          className={getTabClassName('Price List')}
          onClick={() => handleTabClick('Price List')}
        >
          Price List
        </button>
        <button
          className={getTabClassName('Service Details')}
          onClick={() => handleTabClick('Service Details')}
        >
          Service Details
        </button>
      </div>

      {selectedTab === 'Price List' ? (
        <PriceList service={selectedService} />
      ) : (
        <ServiceDetails service={selectedService} />
      )}
    </div>
  );
};

export default Tabs;
