import ServiceCard from '@/components/Services/ServiceCard';
import { useStateContext } from '../../context/ContextProvider';
import { Helmet } from 'react-helmet';
import DesktopServiceCard from '@/components/Services/DesktopServiceCard';
import useDeviceDetection from '@/hooks/useDeviceDetection';
import { useFeatureFlagEnabled } from 'posthog-js/react';

const Services = () => {
  const { serviceTypesData } = useStateContext()!;
  const device = useDeviceDetection();

  const flagEnabled = useFeatureFlagEnabled('desktop-service-cards');

  return (
    <div className='flex justify-center items-center w-full md:h-[500px]'>
      <Helmet>
        <meta charSet='utf-8' />
        <meta
          name='description'
          content='We offer free collection and delivery of your laundry for Wash, Dry & Fold | Wash, Dry & Iron | Ironing.'
        />
        <title>Laundry Services | Laundro</title>
        <link rel='canonical' href='https://laundro.co.uk/services' />
      </Helmet>

      <div className='flex flex-col justify-center items-center w-[90%] h-full'>
        <div className='flex flex-wrap gap-6 justify-center items-center'>
          {serviceTypesData?.map((serviceType) =>
            device === 'Desktop' && flagEnabled ? (
              <DesktopServiceCard
                key={serviceType.shortCode}
                serviceType={serviceType}
              />
            ) : (
              <ServiceCard
                key={serviceType.shortCode}
                serviceType={serviceType}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Services;
