import * as SVGs from '../../assets/SVG/ironing/index';
import { useStateContext } from '../../context/ContextProvider';

// Define your custom props
interface SvgGeneratorProps extends React.SVGProps<SVGSVGElement> {
	icon: keyof typeof SVGs;
}

const SvgGenerator = (props: SvgGeneratorProps) => {
	const { icon, ...svgProps } = props;

	const { theme } = useStateContext()!;

	if (!(icon in SVGs)) {
		console.error(`Icon "${icon}" not found in SVGs object.`);
		return null;
	}

	const IconComponent = SVGs[icon];

	return (
		<svg
			id={icon}
			data-name={icon}
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 56 56'
			width='56'
			height='56'
			{...svgProps}
			fill='transparent'
			stroke={theme === 'dark' ? '#fff' : '#000'}
			strokeWidth='2'
		>
			<defs>
				<style>{'.cls-1 { fill: none; stroke: black; }'}</style>
			</defs>
			<g>
				<IconComponent />
			</g>
		</svg>
	);
};

export default SvgGenerator;
