import WasherSpinner from '@/assets/SVG/WasherSpinner';
import { useStateContext } from '@/context/ContextProvider';
import { createStripeCheckout } from '@/utils/api';
import { checkUserHasAnAddress } from '@/utils/checkUserHasAnAddress';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const PlaceOrderButton = () => {
  const {
    basket,
    loggedInUser,
    selectedDate,
    orderData,
    deliveryMethodId,
    loggedInUserToken,
    customerNotes,
  } = useStateContext()!;

  const [isLoadingRequest, setIsLoadingRequest] = useState(false);

  const total = orderData?.basketItems?.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  const hasValidAddress = checkUserHasAnAddress(loggedInUser);
  const isDateSelected = selectedDate !== '';

  const isLoggedIn = !!loggedInUser;

  const canPlaceOrder =
    isLoggedIn &&
    hasValidAddress &&
    basket.length > 0 &&
    isDateSelected &&
    total >= 15;

  const tooltipMessage = !isLoggedIn
    ? 'Please login to make an order'
    : !isDateSelected
    ? 'Please select a collection date'
    : !hasValidAddress
    ? 'Please set a valid address in your profile'
    : total < 15
    ? 'Minimum order total is £15'
    : null;

  const buttonLabel = isLoggedIn ? 'Place Order' : 'Login';

  const handleStripeCheckout = async () => {
    setIsLoadingRequest(true);

    try {
      const stripeBasket = basket.map((item) => {
        const items = {
          price: item.id.toLocaleString(),
          quantity: item.quantity,
        };

        return items;
      });

      const res = await createStripeCheckout(
        stripeBasket,
        loggedInUser,
        deliveryMethodId,
        loggedInUserToken,
        selectedDate
      );

      localStorage.setItem('customerNotes', JSON.stringify(customerNotes));
      localStorage.setItem(
        'deliveryMethodId',
        JSON.stringify(deliveryMethodId)
      );
      localStorage.setItem('selectedDate', JSON.stringify(selectedDate));

      window.location.assign(res.url);

      setTimeout(() => {
        setIsLoadingRequest(false);
      }, 1000);
    } catch (error) {
      console.log(
        '%cOrderSummaryPrices.tsx line:102 error',
        'color: #26bfa5;',
        error
      );
    }
  };

  return basket.length ? (
    <div className='tooltip w-full' data-tip={tooltipMessage}>
      <Link to={!isLoggedIn ? '/login' : ''}>
        {total < 15 && (
          <p className='text-error mt-2'>Minimum order total is £15.00</p>
        )}
        {!selectedDate && (
          <p className='text-error mt-2'>Please select a collection date</p>
        )}
        {!checkUserHasAnAddress(loggedInUser) && (
          <p className='text-error mt-2'>
            Please enter a valid address on your{' '}
            <Link className='underline italic' to={'/profile'}>
              profile
            </Link>
          </p>
        )}
        <button
          className='btn btn-primary w-full mt-6 text-white'
          onClick={handleStripeCheckout}
          disabled={!canPlaceOrder}
        >
          {isLoadingRequest ? <WasherSpinner /> : buttonLabel}
        </button>
      </Link>
    </div>
  ) : null;
};

export default PlaceOrderButton;
