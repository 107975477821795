import { Link } from 'react-router-dom';
import LaundroIconSVG from '../assets/SVG/LaundroIconSVG';
import { Helmet } from 'react-helmet';
import { servicesEndpoints } from '@/constants/endpoints';

const Footer = () => {
  return (
    <footer className='footer p-10 bg-base-200 text-base-content mt-10 bottom-0'>
      <Helmet>
        <meta charSet='utf-8' />
        <meta
          name='description'
          content={`Laundro - Laundry to go. We offer free collection and delivery of your laundry for Washing, Drying and Ironing services!`}
        />
      </Helmet>

      <aside className='flex justify-center items-center gap-4'>
        <LaundroIconSVG />

        <div>
          <p className='font-bold text-xl'>Laundro.</p>
          <span className='font-light text-sm'>Laundry, to go.</span>
        </div>
      </aside>

      <nav>
        <header className='footer-title'>Services</header>
        <ul className='flex flex-col gap-2'>
          {servicesEndpoints.map((endpoint, index) => (
            <li key={index}>
              <Link
                to={endpoint.endpoint}
                target={endpoint.page === 'Blog' ? '_blank' : ''}
              >
                {endpoint.page === 'Home' ? null : endpoint.page}
              </Link>
            </li>
          ))}
        </ul>
        <br />

        <header className='footer-title'>Coming Soon!</header>
        <p className='link link-hover'>Alterations</p>
      </nav>
      <nav>
        <header className='footer-title'>Company</header>
        <Link to={'/about'} className='link link-hover'>
          About us
        </Link>
        <Link to={'/contact'} className='link link-hover'>
          Contact
        </Link>
        {/* <a className='link link-hover'>Jobs</a> */}
        <Link to={'/how-it-works'} className='link link-hover'>
          How It Works
        </Link>
      </nav>
      <nav>
        <header className='footer-title'>Legal</header>
        <Link to={'terms-of-use'} className='link link-hover'>
          Terms of use
        </Link>
        <Link to={'/privacy-policy'} className='link link-hover'>
          Privacy policy
        </Link>
      </nav>
    </footer>
  );
};

export default Footer;
