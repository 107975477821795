const TshirtSvg = () => (
	<g>
		<path d='m15.78.92l-6.84,2.68c-2.08.81-3.7,2.5-4.44,4.61L.84,18.64c-.36,1.01.34,2.09,1.41,2.2l7.26.7.07,19.17c0,.77.5,1.47,1.23,1.71,7.76,2.56,15.49,2.59,23.2,0,.73-.25,1.22-.94,1.22-1.71v-19.44s6.93-.49,6.93-.49c1.08-.08,1.79-1.14,1.46-2.17l-3.39-10.33c-.71-2.16-2.34-3.9-4.46-4.73l-6.68-2.65c-.55-.22-1.15-.22-1.7.01-3.36,1.42-6.65,1.5-9.86.05-.56-.25-1.19-.27-1.76-.05Z' />
		<line x1={9.51} y1={21.54} x2={9.51} y2={15.84} />
		<line x1={35.23} y1={21.28} x2={35.23} y2={15.84} />
		<path d='m28.1,1.01c-.15,2.4-2.52,5.24-5.62,5.24s-5.17-2.55-5.62-5.24' />
		<path d='m30.94,1.61c-.45,3.3-3.52,7.61-8.43,7.4s-7.6-3.06-8.35-7.4' />
	</g>
);
export default TshirtSvg;
