const BlouseSvg = () => {
	return (
		<g>
			<path d='m15.74,1.23c-2.84,1.7-5.63,3.12-8.31,4.1-.4.2-.66.57-.69.99-.19,2.56.89,7.25,3.44,12.05s2.39,5.66,2.48,6.91c.03.41-.1.81-.35,1.15l-1.02,1.35,2.15.94-1.63,1.86.53,3.79c8.26-7.64,16.71-7.8,24.53.18l.8-4.18-14.53-13.33c-4.45-4.08-7.15-9.53-7.37-15.28,0-.18-.01-.35-.01-.52Z' />
			<path d='m24.48,18.27s10.04-5.38,9.04-17.51c3.13,2.14,6.1,3.84,8.75,4.6.44.23.72.66.74,1.12.07,1.91-.1,7.04-3.46,11,0,0-.32.27-.74.8-2.15,2.68-2.37,6.26-.65,9.18l.18.31-2.68.74' />
			<path d='m10.39,18.75c-1.49,2.92-1.92,6.88-2.23,11.24H2.39C.78,28.34.78,10.14.78,10.14c-.2-2.76,2.01-5.33,6.23-4.41' />
			<path d='m39.14,18.05c1.49,2.92,2.11,7.59,2.42,11.95h5.77c1.61-1.65,1.61-20.04,1.61-20.04.2-2.76-2.01-5.33-6.23-4.41' />
			<path d='m22.07,28.89c-1.37,4.93-2.16,10.27-2.23,15.88,5.64-1.73,6.45-8.9,5.59-16.15' />
			<path d='m24.78,39.7c2.48,3.09,4.46,5.07,5.52,5.18,0,0,.4-9.01-2.64-15.86' />
		</g>
	);
};

export default BlouseSvg;
