export const faqsData = [
  {
    question: `What services does your laundry business offer?`,
    answer: `We offer comprehensive laundry services, including washing, drying, and ironing. We also provide a Wash & Fold service for those who prefer to skip the ironing.`,
  },
  {
    question: `How does your pickup and delivery service work?`,
    answer: `Our pickup and delivery service is designed for your convenience. Simply schedule a pickup through our website, and our team will collect your laundry at your specified location. We'll then return it to you within 24 hours if you have chosen our Next Day service, freshly cleaned and neatly folded or ironed.`,
  },
  {
    question: `Is there a minimum order requirement for pickup and delivery?`,
    answer: `We have a minimum order requirement of £15 for pickup and delivery. This helps us maintain our efficient service and keep costs low for all customers.`,
  },
  {
    question: `What areas do you serve for pickup and delivery?`,
    answer: `We currently cover the Bradford area (see our home page for our full coverage area). If you're unsure whether we cover your area, please contact our customer support team.`,
  },
  {
    question: `What are your pricing options?`,
    answer: `Our pricing is competitive and depends on the weight and type of items you need us to launder. You can find detailed pricing information on our 'Services & Pricing' page.`,
  },
  {
    question: `Do you offer same-day service?`,
    answer: `At this time, we do not offer same-day service. Our standard turnaround time is 3 days to ensure that we can provide the highest quality cleaning and care for your garments. We also have a Next Day service available at checkout.`,
  },
  {
    question: `How do you handle special laundry requests or garment care instructions?`,
    answer: `We take special care to follow any specific instructions you provide for your garments. You can include these instructions when scheduling your pickup, just leave us a note when placing your order. Our team will ensure your requests are met.`,
  },
  {
    question: `Do I need to provide my laundry detergent or supplies?`,
    answer: `No, you don't need to provide any laundry supplies. We use high-quality detergents and equipment to ensure your clothes are cleaned to the highest standards. If you have any specific allergies or require non-bio detergents, please leave us a note when placing your order.`,
  },
  {
    question: `How can I contact your customer support team if I have questions or concerns?`,
    answer: `You can reach our friendly customer support team by emailing us at hello@laundro.co.uk. We're here to assist you with any inquiries or issues you may have.`,
  },
  {
    question: `What payment methods do you accept?`,
    answer: `We use a secure payment gateway powered by Stripe. We accept various card types including Visa and Mastercard. If you have any issues paying by card, please contact us to discuss an alternative payment method.`,
  },
];
