const BeddingDoubleSvg = () => {
	return (
		<g>
			<path d='m24.78,49.12h20.17c2.2,0,3.98-1.78,3.98-3.98V4.65c0-2.2-1.78-3.98-3.98-3.98h-20.17s.04,0,.04,0H4.65C2.46.67.67,2.46.67,4.65v40.48c0,2.2,1.78,3.98,3.98,3.98h20.17-.04Z' />
			<path d='m18.95,3.83l1.38-.35c.47-.12.9.29.8.77l-.26,1.24.03.17c.43,2.18.41,4.42-.04,6.6h0s.2,1.52.2,1.52c.06.43-.32.8-.75.73l-1.32-.21c-6.29,2.3-12.54,0-12.54,0l-1.35.11c-.43.03-.77-.34-.7-.76l.24-1.38h0c-.49-2.19-.54-4.45-.13-6.66l.02-.1-.3-1.15c-.12-.47.29-.9.76-.81l1.45.3s6.25-2.3,12.54,0' />
			<path d='m42.56,3.83l1.38-.35c.47-.12.9.29.8.77l-.26,1.24.03.17c.43,2.18.41,4.42-.04,6.6h0s.2,1.52.2,1.52c.06.43-.32.8-.75.73l-1.32-.21c-6.29,2.3-12.54,0-12.54,0l-1.35.11c-.43.03-.77-.34-.7-.76l.24-1.38h0c-.49-2.19-.54-4.45-.13-6.66l.02-.1-.3-1.15c-.12-.47.29-.9.76-.81l1.45.3s6.25-2.3,12.54,0' />
			<path d='m.67,17.85l48.26,9.72-5.42,7.32c-.56.76-1.57,1.03-2.43.65L.67,17.85Z' />
		</g>
	);
};

export default BeddingDoubleSvg;
