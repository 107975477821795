import { useState } from 'react';
import CancelSVG from '../assets/SVG/CancelSVG';
import TickSVG from '../assets/SVG/TickSVG';
import EditPencilSVG from '../assets/SVG/EditPencilSVG';

const EditableField = ({
	label,
	value,
	onSave,
}: {
	label: string;
	value: string | undefined;
	onSave: (newValue: string | undefined) => void;
}) => {
	const [isEditing, setIsEditing] = useState(false);
	const [fieldValue, setFieldValue] = useState(value);

	const handleEdit = () => {
		setIsEditing(true);
	};

	const handleSave = () => {
		setIsEditing(false);
		onSave(fieldValue);
	};

	const handleCancel = () => {
		setIsEditing(false);
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFieldValue(e.target.value);
	};

	return (
		<div className='flex justify-between items-center -mt-2 max-w-[350px]'>
			<label className='label font-semibold'>{label}: </label>
			{isEditing ? (
				<div className='flex gap-2'>
					<input
						type='text'
						className='input input-bordered input-sm text-primary'
						value={fieldValue}
						onChange={handleChange}
						autoFocus
					/>
					<div className='flex'>
						<button className='text-error' onClick={handleCancel}>
							<CancelSVG />
						</button>
						<button className='text-success' onClick={handleSave}>
							<TickSVG />
						</button>
					</div>
				</div>
			) : (
				<div className='flex gap-2'>
					<p>{fieldValue}</p>
					<button onClick={handleEdit}>
						<EditPencilSVG />
					</button>
				</div>
			)}
		</div>
	);
};
export default EditableField;
