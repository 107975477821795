import { Link } from 'react-router-dom';
import ShoppingCart from './ShoppingCart';
import Avatar from './Avatar';
import { useStateContext } from '../context/ContextProvider';
import { isUserAnAdmin } from '../utils/isUserAnAdmin';
import { endpoints } from '../constants/endpoints';
import Toggle from './Toggle';
import LaundroFullLogoSVG from '../assets/SVG/LaundroFullLogoSVG';
import { NavigationMenu, NavigationMenuList } from './ui/navigation-menu';

const Navbar = () => {
  const { loggedInUser } = useStateContext()!;

  return (
    <div className='navbar bg-base-100 shadow fixed top-0 left-0 z-10'>
      {/* START */}
      <div className='navbar-start'>
        <div className='dropdown'>
          <label tabIndex={0} className='btn btn-ghost lg:hidden'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-5 w-5'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M4 6h16M4 12h8m-8 6h16'
              />
            </svg>
          </label>
          <ul
            tabIndex={0}
            className='menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52'
          >
            {isUserAnAdmin(loggedInUser) ? (
              <ul className='menu menu-horizontal px-1'>
                <li>
                  <Link to={'/dashboard'}>Dashboard</Link>
                </li>
              </ul>
            ) : (
              <>
                {endpoints.map((endpoint, index) => (
                  <li key={index}>
                    <Link to={endpoint.endpoint}>
                      {endpoint.page === 'Home' ? null : endpoint.page}
                    </Link>
                  </li>
                ))}
              </>
            )}
          </ul>
        </div>

        <Link to={'/'} className='btn btn-ghost normal-case text-xl'>
          <LaundroFullLogoSVG />
        </Link>
      </div>

      {/* CENTER */}
      <div className='navbar-center hidden lg:flex'>
        {isUserAnAdmin(loggedInUser) ? (
          <ul className='menu menu-horizontal px-1'>
            <li>
              <Link to={'/dashboard'}>Dashboard</Link>
            </li>
          </ul>
        ) : (
          <ul className='menu menu-horizontal px-1'>
            {endpoints.map((endpoint, index) => (
              <li key={index}>
                <Link
                  to={endpoint.endpoint}
                  target={endpoint.page === 'Blog' ? '_blank' : ''}
                >
                  {endpoint.page === 'Home' ? null : endpoint.page}
                </Link>
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* END */}
      <div className='navbar-end'>
        <div className='flex justify-center items-center'>
          <NavigationMenu>
            <NavigationMenuList>
              <ShoppingCart />
              <Avatar />
              <Toggle />
            </NavigationMenuList>
          </NavigationMenu>
        </div>

        {/* BOOK NOW */}
        <Link to={'/laundry/services'} className='btn btn-primary ml-4'>
          Book Now
        </Link>

        {/* NOTIFICATIONS */}
        {/* <button className='btn btn-ghost btn-circle'>
          <div className='indicator'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-5 w-5'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9'
              />
            </svg>
            <span className='badge badge-xs badge-primary indicator-item'></span>
          </div>
        </button> */}
      </div>
    </div>
  );
};

export default Navbar;
