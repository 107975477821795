import { useStateContext } from '../context/ContextProvider';
import {
  NavigationMenuItem,
  NavigationMenuTrigger,
  NavigationMenuContent,
  NavigationMenuLink,
} from '@/components/ui/navigation-menu';

import { navigationMenuTriggerStyle } from '@/components/ui/navigation-menu';

import { FaShoppingBasket } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const ShoppingCart = () => {
  const { basket } = useStateContext()!;

  // Calculate the total number of items in the basket
  const totalItems = basket.reduce((total, item) => total + item.quantity, 0);

  // Calculate the total price
  const totalPrice = basket
    .reduce((total, item) => {
      const itemPrice = item.price * item.quantity;

      return total + itemPrice;
    }, 0)
    .toFixed(2);

  return (
    <NavigationMenuItem>
      <NavigationMenuTrigger>
        <div className='indicator'>
          <FaShoppingBasket size={18} />

          {totalItems > 0 && (
            <span className='badge badge-sm indicator-item bg-transparent border-none text-primary'>
              {totalItems}
            </span>
          )}
        </div>
      </NavigationMenuTrigger>

      <NavigationMenuContent className='right-0 absolute left-auto top-full w-auto rounded-lg px-8 py-4'>
        <div className='flex flex-col justify-start items-start'>
          <span className='font-bold text-lg'>{totalItems} item(s)</span>
          <span className='text-info'>{`Subtotal: £${totalPrice}`}</span>

          <Link to='/checkout' className='btn btn-primary'>
            <NavigationMenuLink className={navigationMenuTriggerStyle()}>
              <p className='font-bold'>View Cart</p>
            </NavigationMenuLink>
          </Link>
        </div>
      </NavigationMenuContent>
    </NavigationMenuItem>
  );
};

export default ShoppingCart;
