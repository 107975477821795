import { useStateContext } from '../../context/ContextProvider';
import AvailableDates from './AvailableDates';
import DeliveryMethodSelection from './DeliveryMethodSelection';
import PlaceOrderButton from './PlaceOrderButton';

const OrderSummaryPrices = () => {
  const {
    basket,
    setCustomerNotes,
    deliveryMethodId,
    setDeliveryMethodId,
    selectedDate,
    setSelectedDate,
  } = useStateContext()!;

  return basket.length > 0 ? (
    <div className={`mt-10 sm:mt-0 px-4 p-4 rounded-lg bg-base-200`}>
      <div>
        <div className={`rounded-lg py-2`}>
          <div className='w-full'>
            <h3 className='text-xl font-bold mb-2'>Any notes for us?</h3>
            <p className='text-gray-400 text-sm mb-4'>
              If you have any special requirements leave a note for our team
              here!
            </p>
            <textarea
              className='textarea w-full min-h-[100px] placeholder:text-gray-600'
              placeholder='Wash at 45 degrees, use non-bio or want your ironed garments folded instead of on hangers?'
              onChange={(e) => setCustomerNotes(e.target.value)}
            ></textarea>
          </div>
        </div>

        <div className='divider'></div>

        <AvailableDates
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
        <DeliveryMethodSelection
          setDeliveryMethodId={setDeliveryMethodId}
          deliveryMethodId={deliveryMethodId}
        />
      </div>

      <PlaceOrderButton />
    </div>
  ) : null;
};

export default OrderSummaryPrices;
