import { Link, useNavigate } from 'react-router-dom';
import { useStateContext } from '../context/ContextProvider';
import {
  NavigationMenuItem,
  NavigationMenuTrigger,
  NavigationMenuContent,
  NavigationMenuLink,
} from '@/components/ui/navigation-menu';
import { navigationMenuTriggerStyle } from './ui/navigation-menu';

import { FaUser } from 'react-icons/fa';

const Avatar = () => {
  const { loggedInUser } = useStateContext()!;
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('loggedInUserInfo');

    navigate('/login');
    window.location.reload();
  };

  return (
    <>
      {loggedInUser ? (
        <NavigationMenuItem>
          <NavigationMenuTrigger>
            <FaUser size={16} />
          </NavigationMenuTrigger>

          <NavigationMenuContent className='right-0 absolute left-auto top-full w-auto rounded-lg px-4 py-2'>
            <Link to={'/profile'}>
              <NavigationMenuLink className={navigationMenuTriggerStyle()}>
                <p className='font-bold'>Profile</p>
              </NavigationMenuLink>
            </Link>
            <Link to={'/orders-and-subscriptions'}>
              <NavigationMenuLink className={navigationMenuTriggerStyle()}>
                <p className='font-bold'>Orders & Subscriptions</p>
              </NavigationMenuLink>
            </Link>

            <NavigationMenuLink className={navigationMenuTriggerStyle()}>
              <button onClick={handleLogout} className='font-bold'>
                Logout
              </button>
            </NavigationMenuLink>
          </NavigationMenuContent>
        </NavigationMenuItem>
      ) : (
        <Link to={'/login'}>
          <FaUser size={16} />
        </Link>
      )}
    </>
  );
};

export default Avatar;
