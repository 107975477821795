import { Link, useLocation } from 'react-router-dom';
import { useStateContext } from '../../context/ContextProvider';

const NavigationPills = () => {
  const { serviceTypesData } = useStateContext()!;
  const location = useLocation();

  return (
    <div className='tabs tabs-boxed w-full sm:w-[500px] grid grid-cols-2 sm:flex justify-center items-center uppercase'>
      {serviceTypesData.map((serviceType) => {
        const isActive =
          location.pathname === `/laundry/services/${serviceType.shortCode}`;

        return (
          <Link
            to={`/laundry/services/${serviceType.shortCode}`}
            className={`tab ${isActive ? 'bg-primary text-white' : ''}`}
            key={serviceType.id}
          >
            {serviceType.shortCode}
          </Link>
        );
      })}
    </div>
  );
};

export default NavigationPills;
