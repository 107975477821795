import { UsersOrderData } from '@/types';

type StatusKey = keyof typeof statusMapper;

const statusMapper = {
  cancelled: 'Cancelled',
  delivered: 'Completed',
  collected: 'Collected',
  confirmed: 'Confirmed',
};

const getStatusLabel = (order: UsersOrderData): string => {
  for (const key of Object.keys(statusMapper) as StatusKey[]) {
    if (order[key]) {
      return statusMapper[key];
    }
  }
  return '';
};

export default getStatusLabel;
