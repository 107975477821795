import { useState } from 'react';
import { faqsData } from '../data/faqsData';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const FAQs = () => {
  const [activeIndex, setActiveIndex] = useState(-1);

  const toggleAccordion = (index: number) => {
    setActiveIndex(index === activeIndex ? -1 : index);
  };

  return (
    <div className='flex flex-col gap-2 prose'>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Laundry Questions | FAQs | Laundro</title>
        <meta
          name='description'
          content='Have any questions for the Laundro team or about our laundry services? Check here first.'
        />
        <link rel='canonical' href='https://laundro.co.uk/faqs' />
      </Helmet>

      <section className='flex flex-col justify-center items-center'>
        <h1>FAQs</h1>
        <p>
          {`Most of our customers find what they need here, these are the most
          popular queries we receive about our laundry services. If you can't
          find the answer to your question - you can contact us `}{' '}
          <span>
            <Link to={'/contact'}>here</Link>
          </span>
        </p>
      </section>

      {faqsData.map((faq, index) => {
        const isActive = index === activeIndex;
        return (
          <div
            key={index}
            className={`collapse collapse-plus bg-base-200 ${
              isActive ? 'bg-primary text-white' : ''
            }`}
          >
            <input
              type='radio'
              name='my-accordion-3'
              checked={isActive}
              onChange={() => toggleAccordion(index)}
            />
            <div className='collapse-title text-xl font-medium'>
              {faq.question}
            </div>
            <div className='collapse-content'>
              <p>{faq.answer}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FAQs;
