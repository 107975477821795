import { Link } from 'react-router-dom';
import { ServiceType } from '../../types';
import { getLowestPrice } from '@/utils/getLowestPrice';

const ServiceCard = ({ serviceType }: { serviceType: ServiceType }) => {
  return (
    <Link
      to={`/laundry/services/${serviceType.shortCode}`}
      className='card w-full sm:w-96 shadow-xl image-full no-underline z-0'
    >
      <figure className='h-40 md:h-48'>
        <img
          src={serviceType.icon}
          alt={serviceType.title}
          className='w-full object-cover object-bottom'
        />
      </figure>
      <div className='flex flex-col justify-end items-start text-white z-10 p-4'>
        <div className='flex gap-1 absolute top-4 right-4'>
          {serviceType.tags.map((tag) => (
            <div key={tag} className='badge badge-sm badge-ghost'>
              {tag}
            </div>
          ))}
        </div>
        <h1 className='text-lg font-bold'>{serviceType.title}</h1>
        <p className='text-sm'>
          From{' '}
          <span className=''>
            £{getLowestPrice(serviceType?.serviceOptions) || '0.50'}
          </span>
        </p>
      </div>
    </Link>
  );
};

export default ServiceCard;
