import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
	return (
		<div className='prose'>
			<Helmet>
				<meta charSet='utf-8' />
				<meta name='description' content='Our Privacy Policy documents for Laundro' />
				<title>Privacy Policy | Laundro</title>
				<link rel='canonical' href='https://laundro.co.uk/privacy-policy' />
			</Helmet>

			<h1>Privacy Policy for Laundro</h1>
			<div>
				<h2>Last Updated: 24th September 2023</h2>
				<h3>
					{`Laundro ("us," "we," or "our") is committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your data when you use our services, including our website and mobile applications.`}
				</h3>
				<ol>
					<li>
						<span className='font-bold text-lg'>Information We Collect</span>

						<ul>
							<li>
								We may collect the following types of personal information when
								you use our services:
							</li>

							<ol type='a'>
								<li>
									Contact Information: This may include your name, email
									address, phone number, and address.
								</li>
								<li>
									Account Information: If you create an account with us, we may
									collect your username, password, and other registration
									details.
								</li>
								<li>
									Order Information: Information related to your laundry orders,
									including items, preferences, and payment details.
								</li>
								<li>
									Usage Information: Data about how you interact with our
									website and mobile applications, including your IP address,
									browser type, operating system, and browsing history.
								</li>
							</ol>
						</ul>
					</li>

					<li>
						<span className='font-bold text-lg'>
							How We Use Your Information
						</span>

						<ul>
							<li>
								We may use your personal information for the following purposes:
							</li>

							<ol type='a'>
								<li>
									Service Provision: To provide our laundry services, process
									orders, and deliver your laundry.
								</li>
								<li>
									Customer Support: To respond to your inquiries, requests, and
									complaints.
								</li>
								<li>
									Communication: To send you updates, service-related
									announcements, and promotional offers with your consent.
								</li>
								<li>
									Improvement: To enhance our services, website, and user
									experience.
								</li>
							</ol>
						</ul>
					</li>

					<li>
						<span className='font-bold text-lg'>Data Sharing</span>

						<ul>
							<li>
								We may share your personal information with third parties in the
								following situations:
							</li>

							<ol type='a'>
								<li>
									Service Providers: We may share your information with
									third-party service providers who assist us in operating our
									business, such as payment processors, delivery services, and
									customer support.
								</li>
								<li>
									Legal Compliance: We may disclose your data when required by
									law or to protect our rights, privacy, safety, or property.
								</li>
							</ol>
						</ul>
					</li>

					<li>
						<span className='font-bold text-lg'>Data Security</span>

						<ul>
							<li>
								We implement security measures to protect your personal
								information, including encryption and access controls. However,
								please note that no method of data transmission over the
								internet is entirely secure, and we cannot guarantee absolute
								security.
							</li>
						</ul>
					</li>

					<li>
						<span className='font-bold text-lg'>Your Rights</span>

						<ul>
							<li>
								You have certain rights regarding your personal information
								under UK data protection laws, including the right to access,
								correct, delete, or restrict the processing of your data. To
								exercise these rights or make inquiries about your data, please
								contact us at hello@laundro.co.uk.
							</li>
						</ul>
					</li>

					<li>
						<span className='font-bold text-lg'>
							Changes to this Privacy Policy
						</span>

						<ul>
							<li>
								{`We may update this Privacy Policy to reflect changes in our
								practices or legal requirements. Updates will be posted on our
								website, and the "Last Updated" date will be revised
								accordingly.`}
							</li>
						</ul>
					</li>

					<li>
						<span className='font-bold text-lg'>Contact Information</span>

						<ul>
							<li>
								If you have any questions or concerns regarding this Privacy
								Policy, please contact us at hello@laundro.co.uk.
							</li>
						</ul>
					</li>
				</ol>

				<p>
					By using our services, you consent to the practices outlined in this
					Privacy Policy. If you do not agree with any part of this policy,
					please refrain from using our services.
				</p>
			</div>
		</div>
	);
};

export default PrivacyPolicy;
