const ShirtSvg = () => (
	<g>
		<path d='m14.78.85l-4.61,3.53s-.04.03-.06.04l-6.17,3.42c-1.97,1.09-3.19,3.16-3.19,5.41v32.46c0,.26.21.46.46.46h5.28c.26,0,.47-.22.46-.48l-.05-1.13c-.01-.32.3-.56.61-.46,8.5,2.73,16.89,2.81,25.16,0,.3-.1.61.12.61.44v1.17c0,.26.21.46.46.46h5.78c.26,0,.46-.21.46-.46V13.29c0-2.31-1.29-4.43-3.34-5.49l-6.41-3.33s-.05-.03-.07-.05l-4.61-3.58c-.08-.06-.18-.1-.28-.1-3.4,1.18-6.8,1.18-10.21,0-.1,0-.2.03-.28.1Z' />
		<path d='m6.9,44.55l.54-6.65c.59-7.2.27-14.45-.95-21.58h0' />
		<path d='m33.28,44.53l-.43-8.73c-.32-6.5-.04-13.02.84-19.48h0' />
		<polyline points='14.78 .85 20.37 5.71 25.56 .85' />
		<path d='m10.11,4.41l5.43,5.52c.48.48,1.27.43,1.69-.11l3.14-4.11,3.1,4.08c.42.55,1.23.59,1.71.09l5.06-5.4' />
		<line x1={20.39} y1={46.17} x2={20.39} y2={5.71} />
		<line x1={0.75} y1={42.61} x2={6.9} y2={42.61} />
		<line x1={33.84} y1={42.61} x2={39.99} y2={42.61} />
		<circle cx={23.53} cy={15.54} r={0.35} />
		<circle cx={23.53} cy={23.53} r={0.35} />
		<circle cx={23.53} cy={31.24} r={0.35} />
		<circle cx={23.53} cy={39.23} r={0.35} />
	</g>
);
export default ShirtSvg;
