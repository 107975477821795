const BabyClothesSvg = () => {
	return (
		<g>
			<path d='m13.94,1.02c3.53,1.95,7.07,1.96,10.6.03.6-.33,1.29-.46,1.97-.36,2.26.33,7.41,1.23,10.3,2.86.67.38,1.01,1.17.82,1.92l-1.3,5.13c-.19.76-.88,1.29-1.66,1.29h-1.89c-.83,0-1.5.68-1.48,1.51l.32,17.58c.01.7-.34,1.35-.94,1.73-1.36.85-3.96,2.77-6.31,6.25l-1.03-.7c-.26-.18-.57-.27-.89-.27h-6.79c-.43,0-.84.18-1.14.49l-.47.49s-1.92-3.38-6.17-6.34c-.55-.38-.88-1.01-.86-1.67l.3-18.21c.01-.83-.65-1.51-1.48-1.51h-2.34c-.78,0-1.46-.52-1.66-1.27L.69,5.68c-.22-.81.18-1.66.94-2,1.95-.89,5.87-2.47,10.41-3.03.66-.08,1.32.05,1.9.37Z' />
			<path d='m9.03,33.47l2.07,7.61c.2.75.89,1.28,1.67,1.28h12.83c.76,0,1.44-.5,1.66-1.23l2.29-7.66' />
			<line x1={14.05} y1={38.96} x2={11.69} y2={41.99} />
			<line x1={24.37} y1={38.96} x2={26.63} y2={41.79} />
			<path d='m6.95,1.71s3.17,4.76,0,10.04' />
			<path d='m31.59,1.71s-3.17,4.76,0,10.04' />
			<path d='m8.36,6.73C13.31,5.67,13.36.78,13.36.78' />
			<path d='m25.17.78s.05,4.89,5.01,5.95' />
			<path d='m13.25,1.71s5.62,9.07,12.07,0' />
		</g>
	);
};

export default BabyClothesSvg;
