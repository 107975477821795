import { UsersOrderData } from '@/types';

const calculateTotalRevenueForPeriod = (orders: UsersOrderData[]) => {
  const totalRevenue = orders.reduce((acc, order) => {
    acc += order.totalPrice;
    return acc;
  }, 0);

  return totalRevenue;
};

export default calculateTotalRevenueForPeriod;
