import { ImBin } from 'react-icons/im';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { useStateContext } from '@/context/ContextProvider';
import { toastNotification } from '@/utils/toastNotification';
import { BasketItem } from '@/types';

interface QuantityPickerProps {
  item: BasketItem;
}

const QuantityPicker = ({ item }: QuantityPickerProps) => {
  const { basket, setBasket } = useStateContext()!;

  const removeBasketItem = (itemToRemove: BasketItem) => {
    const updatedBasket = basket.filter((item) => {
      return item.shortCode !== itemToRemove.shortCode;
    });
    setBasket(updatedBasket);
    toastNotification(
      `Removed ${itemToRemove.title} from your basket`,
      'bg-success text-base-100'
    );
  };

  const incrementQuantity = (item: BasketItem) => {
    const updatedBasket = basket.map((basketItem) => {
      if (basketItem.shortCode === item.shortCode) {
        return { ...basketItem, quantity: basketItem.quantity + 1 };
      }
      return basketItem;
    });
    setBasket(updatedBasket);
  };

  const decrementQuantity = (item: BasketItem) => {
    const updatedBasket = basket.map((basketItem) => {
      if (basketItem.shortCode === item.shortCode && basketItem.quantity > 1) {
        return { ...basketItem, quantity: basketItem.quantity - 1 };
      }
      return basketItem;
    });
    setBasket(updatedBasket);
  };
  return (
    <div className='flex justify-center items-center gap-4'>
      <div className='flex h-10 w-[116.5px] rounded-lg'>
        <button
          className='bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-12 rounded-l cursor-pointer outline-none flex justify-center items-center'
          onClick={() => decrementQuantity(item)}
          disabled={item.quantity === 1}
        >
          <FaMinus size={10} />
        </button>
        <span className='focus:outline-none w-1/2 bg-gray-300 font-semibold text-md hover:text-black focus:text-black md:text-basecursor-default flex justify-center items-center text-gray-700 outline-none'>
          {item.quantity}
        </span>
        <button
          className='bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-12 rounded-r cursor-pointer outline-none flex justify-center items-center'
          onClick={() => incrementQuantity(item)}
        >
          <FaPlus size={10} />
        </button>
      </div>
      <button className='text-error' onClick={() => removeBasketItem(item)}>
        <ImBin />
      </button>
    </div>
  );
};

export default QuantityPicker;
