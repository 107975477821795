import { UsersOrderData } from '@/types';
import { Button } from '../ui/button';
import { useEffect, useState } from 'react';
import { updateOrderById } from '@/utils/api';
import { useStateContext } from '@/context/ContextProvider';

interface OrderStatusButtonProps {
  currentOrder: UsersOrderData;
  setCurrentOrder: React.Dispatch<
    React.SetStateAction<UsersOrderData | undefined>
  >;
}

const OrderStatusButton = ({
  currentOrder,
  setCurrentOrder,
}: OrderStatusButtonProps) => {
  const { loggedInUserToken } = useStateContext()!;
  const [nextStatus, setNextStatus] = useState<string | null>(null);

  useEffect(() => {
    determineNextStatus();
  }, [currentOrder]);

  const determineNextStatus = () => {
    if (
      currentOrder.confirmed &&
      !currentOrder.collected &&
      !currentOrder.cancelled
    ) {
      setNextStatus('collected');
    } else if (
      currentOrder.collected &&
      !currentOrder.delivered &&
      !currentOrder.cancelled
    ) {
      setNextStatus('delivered');
    } else {
      setNextStatus(null);
    }
  };

  const handleStatusUpdate = async () => {
    if (nextStatus) {
      let fieldToUpdate: string;
      switch (nextStatus) {
        case 'collected':
          fieldToUpdate = 'collected';
          break;
        case 'delivered':
          fieldToUpdate = 'delivered';
          break;
        default:
          fieldToUpdate = '';
      }

      setNextStatus(null);
      setCurrentOrder((prevOrder: UsersOrderData | undefined) => {
        if (!prevOrder) {
          return prevOrder;
        }
        return {
          ...prevOrder,
          [fieldToUpdate]: true,
        };
      });

      const updatedOrderStatus = await updateOrderById(
        currentOrder.id,
        {
          [fieldToUpdate]: true,
        },
        loggedInUserToken
      );

      if (updatedOrderStatus) {
        console.log(updatedOrderStatus);
      }
    }
  };

  return (
    <Button
      onClick={handleStatusUpdate}
      disabled={!nextStatus}
      className='btn-primary bg-secondary'
    >
      {nextStatus ? `Mark as ${nextStatus}` : 'No further action'}
    </Button>
  );
};

export default OrderStatusButton;
