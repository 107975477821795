import { SVGProps } from 'react';
import { JSX } from 'react/jsx-runtime';

const TickSVG = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => {
	return (
		<svg
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			className='w-8 h-8 fill-success'
			{...props}
		>
			<g id='SVGRepo_bgCarrier' strokeWidth={0} />
			<g
				id='SVGRepo_tracerCarrier'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<g id='SVGRepo_iconCarrier'>
				<path
					d='M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z'
					stroke='#292D32'
					strokeWidth={1.5}
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					d='M7.75 12L10.58 14.83L16.25 9.17004'
					stroke='#292D32'
					strokeWidth={1.5}
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</g>
		</svg>
	);
};

export default TickSVG;
