import { Helmet } from 'react-helmet';
import CardFlip from '../components/CardFlip';

const HowITWorks = () => {
	return (
		<div>
			<Helmet>
				<meta charSet='utf-8' />
				<title>How It Works | Laundro</title>
				<meta
					name='description'
					content={`It's as easy as 1-2-3. Place an order > We collect > We return your fresh laundry!`}
				/>
				<link rel='canonical' href='https://laundro.co.uk/how-it-works' />
			</Helmet>

			<CardFlip />
		</div>
	);
};

export default HowITWorks;
