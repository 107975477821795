import { UsersOrderData } from '../../types';

interface Props {
  isSectionActive: (sectionName: string | null) => boolean;
  toggleAccordion: (sectionName: 'Your Details' | 'Your Stats' | null) => void;
  usersOrderData: UsersOrderData[];
}

const YourStats = ({
  isSectionActive,
  toggleAccordion,
  usersOrderData,
}: Props) => {
  return (
    <div
      className={`collapse collapse-arrow bg-base-200 ${
        isSectionActive('Your Stats') ? 'bg-primary text-white' : ''
      }`}
    >
      <input
        type='radio'
        name='my-accordion-3'
        checked={isSectionActive('Your Stats')}
        onChange={() => toggleAccordion('Your Stats')}
      />
      <div className='collapse-title text-xl font-medium'>
        <h3 className='text-xl font-semibold'>Your Stats</h3>
      </div>
      <div className='collapse-content'>
        <div className='flex flex-col gap-2 mt-10'>
          <div className='flex flex-col'>
            <h3>{`Hours you've saved: ${(usersOrderData.length * 1.6).toFixed(
              1
            )} hours`}</h3>
            <h3>{`Water you've saved: ${
              usersOrderData.length * 60
            } litres`}</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YourStats;
