import axios from 'axios';
import { LoggedInUser, OrderDataProps } from '../types';

const api = axios.create({
	baseURL: import.meta.env.VITE_API_URL,
});

// SERVICE TYPES
export const getServiceTypesData = async () => {
	const res = await api.get('/service-types');
	const { data } = res;
	return data;
};

// USERS ----------------------------------------------------------------

// GET USERS
export const getAllUsers = async (userToken: string, user: LoggedInUser) => {
	if (user && user.role === 'admin') {
		const res = await api.get(`/users`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `${userToken}`,
			},
		});
		const { data } = res;
		return data;
	}
};

// GET PRE-REGISTERED USERS
export const getAllPreRegisteredUsers = async (
	userToken: string,
	user: LoggedInUser
) => {
	if (user && user.role === 'admin') {
		const res = await api.get(`/users/pre-register`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `${userToken}`,
			},
		});
		const { data } = res;
		return data;
	}
};

// LOGIN USER
export const loginUser = async (email: string, password: string) => {
	const res = await api.post('/users/login', { email, password });
	const { data } = res;
	return data;
};

// REGISTER USER
export const registerUser = async (
	fullName: string,
	phoneNumber: string,
	email: string,
	password: string
) => {
	const res = await api.post('/users/register', {
		fullName,
		phoneNumber,
		email,
		password,
	});
	const { data } = res;
	return data;
};

// PRE-REGISTER USER
export const preRegisterUser = async (fullName: string, email: string) => {
	const res = await api.post('/users/pre-register', {
		fullName,
		email,
	});
	const { data } = res;
	return data;
};

// UPDATE USER
export const updateUser = async (
	userId: string,
	userToken: string,
	updateData: {
		fullName?: string;
		houseNo?: string;
		street?: string;
		city?: string;
		postcode?: string;
	}
) => {
	const res = await api.put(`/users/${userId}`, updateData, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `${userToken}`,
		},
	});
	const { data } = res;
	return data;
};

// UPDATE USER PASSWORD
export const updateUserPassword = async (email: string, password: string) => {
	const [username, domain] = email.split('@');

	const escapedEmail = `${username}%40${domain}`;

	const res = await api.put(`/users/${escapedEmail}/password`, {
		password,
	});

	const { data } = res;
	return data;
};

// ORDERS ----------------------------------------------------------------

// GET ALL ORDERS
export const getAllOrders = async (userToken: string, user: LoggedInUser) => {
	if (user && user.role === 'admin') {
		const res = await api.get(`/orders`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `${userToken}`,
			},
		});
		const { data } = res;
		return data;
	}
};

// GET COLLECTION DATES FROM EXISTING ORDERS
export const getCollectionDatesFromExistingOrders = async (
	userToken: string
) => {
	const res = await api.get(`/orders/collection-dates`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: userToken,
		},
	});
	const { data } = res;
	return data;
};

// GET ORDERS BY USER ID
export const getOrdersByUserId = async (userId: string, userToken: string) => {
	const res = await api.get(`/orders/user/${userId}`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `${userToken}`,
		},
	});
	const { data } = res;
	return data;
};

export const createOrder = async (
	orderData: OrderDataProps,
	userToken: string,
	customerNotes: string,
	selectedDate: string
) => {
	orderData.notes = customerNotes;
	orderData.collectionDate = selectedDate;

	const res = await api.post('/orders', orderData, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `${userToken}`,
		},
	});
	const { data } = res;
	return data;
};

// UPDATE ORDER BY ID
export const updateOrderById = async (
	orderId: number,
	updatedOrderData: OrderDataProps,
	userToken: string
) => {
	const res = await api.put(`/orders/${orderId}`, updatedOrderData, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `${userToken}`,
		},
	});
	const { data } = res;
	return data;
};

// UPDATE BASKET ITEM STATUS BY ID
export const updateBasketItemStatusById = async (
	basketItemId: number,
	updatedStatus: { [option: string]: boolean },
	userToken: string
) => {
	const res = await api.put(
		`/basketItems/${basketItemId}/status`,
		updatedStatus,
		{
			headers: {
				'Content-Type': 'application/json',
				Authorization: `${userToken}`,
			},
		}
	);
	const { data } = res;
	return data;
};

// DELETE ORDER BY ID
export const deleteOrderById = async (userToken: string, orderId: number) => {
	const res = await api.delete(`/orders/${orderId}`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: userToken,
		},
	});
	const { data } = res;
	return data;
};

//  ----------------------------------------------------------------

// ADDRESS CHECKER
export const findAddressByPostcode = async (postcode: string) => {
	try {
		const res = await api.get(
			`https://api.os.uk/search/names/v1/find?maxresults=1&query=${postcode}`,
			{
				headers: {
					key: import.meta.env.VITE_OS_NAMES_PROJECT_API_KEY,
				},
			}
		);
		const { data } = res;
		return data;
	} catch (error) {
		console.log('%capi.ts line:167 error', 'color: #26bfa5;', error);
	}
};

// DELIVERY METHODS ----------------------------------------------------------------

// GET ALL DELIVERY METHODS
export const getAllDeliveryMethods = async () => {
	const res = await api.get(`/orders/delivery-methods`);
	const { data } = res;
	return data;
};

//  STRIPE ----------------------------------------------------------------
export const getPaymentIntent = async (amount: number) => {
	const res = await api.post('/create-payment-intent', { amount });
	const { data } = res;
	return data;
};

export const createStripeCheckout = async (
	basketItems: {
		price: string;
		quantity: number;
	}[],
	user: LoggedInUser,
	deliveryMethodId: number,
	loggedInUserToken: string,
	collectionDate: string
) => {
	const res = await api.post(
		'/create-stripe-checkout',
		{
			basketItems,
			user,
			deliveryMethodId,
			collectionDate,
		},
		{
			headers: {
				'Content-Type': 'application/json',
				Authorization: loggedInUserToken,
			},
		}
	);
	const { data } = res;
	return data;
};

export const getPaymentIntentId = async (loggedInUser: LoggedInUser) => {
	try {
		const { data } = await api.get(`/payment-intent-id/${loggedInUser.id}`);
		return data;
	} catch (error) {
		console.log('%capi.ts line:284 error', 'color: #26bfa5;', error);
	}
};

// REFUND ORDER BY CHARGE ID
export const refundOrderByChargeId = async (
	latestChargeId: string,
	loggedInUserToken: string,
	loggedInUser: LoggedInUser
) => {
	const res = await api.get(`/refund/${latestChargeId}`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: loggedInUserToken,
			'logged-in-user-id': loggedInUser.id,
		},
	});
	const { data } = res;
	return data;
};
