import { useState } from 'react';
import PlusIconSVG from '../../assets/SVG/PlusIconSVG';
import WasherIconSVG from '../../assets/SVG/WasherIconSVG';
import { useStateContext } from '../../context/ContextProvider';
import { Item, ServiceOption } from '../../types';
import { addToBasket } from '../../utils/addToBasket';
import useDeviceDetection from '../../hooks/useDeviceDetection';
import SvgGenerator from './SvgGenerator';

interface PriceCardProps {
  item?: Item;
  option?: ServiceOption;
  isForCategories?: boolean;
}

const PriceCard = ({ item, option, isForCategories }: PriceCardProps) => {
  const { basket, setBasket, serviceTypesData } = useStateContext()!;
  const device = useDeviceDetection();
  const [showTickAnimation, setShowTickAnimation] = useState(false);

  const basketCheckerForScentBoosters = basket.some(
    (item) => item.title.includes('6kg') || item.title.includes('9kg')
  );

  const handleAddToBasket = () => {
    if (isForCategories && item) {
      addToBasket(
        basket,
        setBasket,
        item,
        serviceTypesData,
        setShowTickAnimation
      );
    } else if (option) {
      addToBasket(
        basket,
        setBasket,
        option,
        serviceTypesData,
        setShowTickAnimation
      );
    }
  };

  return (
    <div className='w-full bg-base-300 flex justify-between items-center gap-4 px-4 py-6 sm:px-10 rounded-lg shadow-lg'>
      <div className='flex gap-4'>
        <div className='justify-center items-center sm:flex hidden'>
          {isForCategories && item ? (
            <SvgGenerator icon={item.icon} />
          ) : (
            <WasherIconSVG />
          )}
        </div>
        <div className='flex flex-col justify-center items-start'>
          <h1 className='text-sm sm:text-lg'>
            {isForCategories && item
              ? item?.info
              : device === 'Mobile'
              ? option?.title.split('-')[1]
              : option?.title}
          </h1>
          {!isForCategories && option && (
            <p className='italic hidden sm:flex text-xs'>
              {option?.description}
            </p>
          )}
        </div>
      </div>
      <div className='flex justify-center items-center gap-4'>
        <p className='font-bold text-md sm:text-lg'>
          £{(isForCategories ? item?.price : option?.price)?.toFixed(2)}
        </p>
        {option?.title.includes('Scent Boosters') &&
        !basketCheckerForScentBoosters ? (
          <div
            className='tooltip tooltip-left'
            data-tip='Please add a wash option first'
          >
            <button className='btn btn-primary' disabled>
              <PlusIconSVG />
            </button>
          </div>
        ) : (
          <button
            className='btn btn-primary disabled:btn-success'
            onClick={handleAddToBasket}
            disabled={showTickAnimation}
          >
            {showTickAnimation ? (
              <span className='animate-pulse text-lg font-bold text-white'>
                &#10003;
              </span>
            ) : (
              <PlusIconSVG />
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default PriceCard;
