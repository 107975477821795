import { BasketItem, LoggedInUser, OrderData } from '../types';

export const convertBasketToOrder = (
	basket: BasketItem[],
	user: LoggedInUser
): OrderData => {
	const orderData: OrderData = {
		userId: user?.id || '',
		basketItems: basket.map((item) => ({
			serviceTypeId: item?.serviceTypeId || item?.item?.serviceTypeId || 0,
			quantity: item?.quantity || 0,
			price: item?.price || 0,
			description: item?.description || '',
			shortCode: item?.shortCode || '',
			title: item?.title || '',
			itemId: null,
		})),
	};

	return orderData;
};
