const ShortsSvg = () => {
	return (
		<g>
			<path d='m9.47.9h31.86c.55,0,1,.45,1,1v2.82H8.47V1.89c0-.55.45-1,1-1Z' />
			<path d='m8.47,4.71l-.9,2.23c-1.42,3.51-2.45,7.17-3.09,10.9L.94,38.66c-.24,1.42.64,2.79,2.03,3.16l13.96,3.73c1.38.37,2.81-.36,3.32-1.7l5.15-13.49,5.44,13.55c.52,1.3,1.94,2.01,3.3,1.65l14.13-3.75c1.4-.37,2.28-1.76,2.03-3.18l-3.66-20.35c-.7-3.88-1.82-7.67-3.36-11.29l-.97-2.28' />
			<line x1={1.26} y1={38.12} x2={20.36} y2={42.84} />
			<line x1={49.52} y1={38.12} x2={30.42} y2={42.84} />
			<path d='m25.4,5.38s-3.8,1.97-5.04,6.65' />
			<path d='m26.64,5.38s3.8,1.97,5.04,6.65' />
		</g>
	);
};

export default ShortsSvg;
