import { Helmet } from 'react-helmet';

const TermsOfUse = () => {
	return (
		<div className='prose'>
			<Helmet>
				<meta charSet='utf-8' />
				<meta
					name='description'
					content='Our Terms Of Use documents for Laundro'
				/>
				<title>Terms Of Use | Laundro</title>
				<link rel='canonical' href='https://laundro.co.uk/terms-of-use' />
			</Helmet>

			<h1>Terms Of Use</h1>
			<div>
				<h2>Last Updated: 24th September 2023</h2>
				<h3>
					{`Welcome to Laundro ("the Service"). By using our website and services,
					you agree to comply with and be bound by the following terms and
					conditions of use. Please review these terms carefully. If you do not
					agree with these terms, please do not use our website or services.`}
				</h3>
				<ol>
					<li>
						<span className='font-bold text-lg'>Acceptance of Terms</span>
						<ul>
							<li>
								By accessing or using the Service, you acknowledge that you have
								read, understood, and agree to be bound by these Terms of Use.
								These terms may be updated from time to time, and the most
								recent version will be posted on our website. It is your
								responsibility to review these terms periodically for changes.
							</li>
						</ul>
					</li>

					<li>
						<span className='font-bold text-lg'>Use of the Service</span>
						<ul>
							<li>{`(a) You must be at least 16 years old to use our services.`}</li>
							<li>{`(b) You agree to provide accurate and complete information when using
				the Service.`}</li>
							<li>{`(c) You are responsible for maintaining the confidentiality of your
				account information and password.`}</li>
							<li>{`(d) You agree not to use the Service for any unlawful or prohibited
				purposes.`}</li>
						</ul>
					</li>

					<li>
						<span className='font-bold text-lg'>Service Availability</span>
						<ul>
							<li>
								We make every effort to ensure the availability of our services.
								However, we may, without notice, restrict or terminate access to
								the Service for maintenance, upgrades, or other reasons.
							</li>
						</ul>
					</li>

					<li>
						<span className='font-bold text-lg'>Privacy Policy</span>
						<ul>
							<li>{` Your use of the Service is also governed by our
				Privacy Policy, which can be found here [Link to privacy policy]. By
				using the Service, you consent to the practices described in the Privacy
				Policy.`}</li>
						</ul>
					</li>

					<li>
						<span className='font-bold text-lg'>Intellectual Property</span>
						<ul>
							<li>{`(a) All content on the Service, including text, graphics, logos, images,
				and software, is the property of Laundro and is protected by copyright
				and other intellectual property laws.`}</li>

							<li>{`(b) You may not reproduce, distribute, modify, or republish any content
				from our website without our prior written consent.`}</li>
						</ul>
					</li>

					<li>
						<span className='font-bold text-lg'>Disclaimer</span>
						<ul>
							<li>{`(a) The Service is provided "as is" and "as available" without
				warranties of any kind, either express or implied.`}</li>

							<li>
								{`(b) We do not guarantee the accuracy, completeness, or
								timeliness of information on the website.`}
							</li>

							<li>{`(c) We are not responsible for any damage or loss resulting from the use
				of the Service.`}</li>
						</ul>
					</li>

					<li>
						<span className='font-bold text-lg'>Limitation of Liability</span>
						<ul>
							<li>
								In no event shall Laundro, its owners, employees, or affiliates
								be liable for any direct, indirect, incidental, special, or
								consequential damages arising out of or in any way connected
								with the use of the Service.
							</li>
						</ul>
					</li>

					<li>
						<span className='font-bold text-lg'>Governing Law</span>
						<ul>
							<li>
								These Terms of Use are governed by and construed in accordance
								with the laws of the United Kingdom. Any disputes arising from
								the use of the Service shall be subject to the exclusive
								jurisdiction of the courts in the United Kingdom.
							</li>
						</ul>
					</li>

					<li>
						<span className='font-bold text-lg'>Contact Information</span>
						<ul>
							<li>
								If you have any questions or concerns about these Terms of Use,
								please contact us at hello@laundro.co.uk. By using the Service,
								you agree to abide by these terms and conditions. If you do not
								agree with any part of these terms, please refrain from using
								our website and services.
							</li>
						</ul>
					</li>
				</ol>
			</div>
		</div>
	);
};

export default TermsOfUse;
