import { Link } from 'react-router-dom';
import { useStateContext } from '../../context/ContextProvider';
import { convertBasketToOrder } from '../../utils/convertBasketToOrder';
import { useEffect } from 'react';
import QuantityPicker from './QuantityPicker';

const OrderSummaryItems = () => {
  const { basket, loggedInUser, setOrderData, deliveryMethodId } =
    useStateContext()!;

  const orderData = convertBasketToOrder(basket, loggedInUser);

  useEffect(() => {
    setOrderData(orderData);

    localStorage.setItem('orderData', JSON.stringify(orderData));
  }, [basket]);

  let total = orderData?.basketItems?.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  return basket.length > 0 ? (
    <div className='px-4 pt-4 rounded-lg bg-base-200 text-center sm:text-left'>
      <p className='text-xl font-bold'>Order Summary</p>
      <p className='text-gray-400 text-sm mt-1'>
        Check your items and proceed to place your order if you are happy with
        your selections!
      </p>

      <div className={`space-y-3 rounded-lg mt-2`}>
        {basket.map((item) => (
          <div key={item.shortCode} className='flex w-full flex-col py-2'>
            <span className='font-bold text-left'>{item.title}</span>
            <div className='flex justify-between items-center mt-2'>
              <QuantityPicker item={item} />

              <p className='text-xl font-bold'>
                £{(item.price * item.quantity).toFixed(2)}
              </p>
            </div>

            <div className='divider'></div>
          </div>
        ))}
      </div>

      <div>
        <div className='flex justify-between items-center'>
          <p>Subtotal:</p>
          <p>£{total?.toFixed(2)}</p>
        </div>
        <div className='flex justify-between items-center'>
          <p>Delivery Charge:</p>

          <p>£{(deliveryMethodId === 1 ? 0 : 3).toFixed(2)}</p>
        </div>
        <div className='flex justify-between items-center mb-4'>
          <p>Total:</p>
          <p className='text-lg font-bold'>
            £{(total += deliveryMethodId === 1 ? 0 : 3).toFixed(2)}
          </p>
        </div>
      </div>
    </div>
  ) : (
    <div className={`mt-8 space-y-3 rounded-lg px-2 py-2 bg-base-200`}>
      <h2 className='text-xl font-bold'>Your basket is empty!</h2>
      <Link className='btn btn-primary' to={'/laundry/services'}>
        Go Shopping
      </Link>
    </div>
  );
};

export default OrderSummaryItems;
