import { ServiceOption } from '@/types';

export const getLowestPrice = (serviceOptions: ServiceOption[]) => {
  if (!serviceOptions || serviceOptions.length === 0) {
    return null;
  }

  const sortedOptions = serviceOptions
    .filter((option) => !option.title.includes('Scent Boosters'))
    .sort((a, b) => a.price - b.price);

  if (sortedOptions.length === 0) {
    return null;
  }

  return sortedOptions[0].price.toFixed(2);
};
