import { Skeleton } from '../ui/skeleton';

const SkeletonCard = () => {
  return (
    <div className='flex flex-col gap-2'>
      <Skeleton className='w-1/3 h-4' />
      <Skeleton className='w-2/3 h-[42px]' />
    </div>
  );
};

export default SkeletonCard;
