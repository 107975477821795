import { useState } from 'react';
import ReactCardFlip from 'react-card-flip';

const CardFlip = () => {
  const [isFlipped1, setIsFlipped1] = useState(false);
  const [isFlipped2, setIsFlipped2] = useState(false);
  const [isFlipped3, setIsFlipped3] = useState(false);

  const handleFirstCardClick = () => {
    setIsFlipped1(!isFlipped1);
  };

  const handleSecondCardClick = () => {
    setIsFlipped2(!isFlipped2);
  };

  const handleThirdCardClick = () => {
    setIsFlipped3(!isFlipped3);
  };

  return (
    <div className='flex flex-col gap-10 justify-center items-center'>
      <div>
        <h1 className='text-5xl font-bold text-center mb-2'>{`It's As Easy As...`}</h1>
        <p className='text-base italic'>
          Click/Tap the cards to see more info on each step
        </p>
      </div>

      <div className='flex flex-col lg:flex-row gap-10'>
        {/* 1 */}
        <ReactCardFlip isFlipped={isFlipped1} flipDirection='horizontal'>
          <div
            onClick={handleFirstCardClick}
            className='rounded-full w-80 h-80 bg-accent hover:bg-accent-focus flex flex-col gap-6 justify-center items-center text-base-100 p-10 text-center cursor-pointer transition-all'
          >
            <h1 className='text-3xl font-bold'>1</h1>
            <p className='text-xl font-semibold'>
              Choose your services and date for collection, then place your
              order
            </p>
          </div>
          <div
            onClick={handleFirstCardClick}
            className='rounded-full w-80 h-80 bg-accent hover:bg-accent-focus flex flex-col gap-2 justify-center items-center text-base-100 p-10 text-center cursor-pointer transition-all'
          >
            <h3 className='text-xl font-bold'>
              Choose from our range of services and select a collection date
              during checkout
            </h3>
            <p className='italic text-base'>
              Tip: Make sure you have added an address in your profile
            </p>
          </div>
        </ReactCardFlip>

        {/* 2 */}
        <ReactCardFlip isFlipped={isFlipped2} flipDirection='horizontal'>
          <div
            onClick={handleSecondCardClick}
            className='rounded-full w-80 h-80 bg-secondary flex flex-col gap-6 justify-center items-center text-base-100 p-10 text-center cursor-pointer transition-all hover:bg-secondary-focus'
          >
            <h1 className='text-3xl font-bold'>2</h1>
            <p className='text-xl font-semibold'>
              Pack your clothes into a bag
            </p>
          </div>
          <div
            onClick={handleSecondCardClick}
            className='rounded-full w-80 h-80 bg-secondary flex flex-col gap-2 justify-center items-center text-base-100 p-10 text-center cursor-pointer transition-all hover:bg-secondary-focus'
          >
            <h3 className='text-xl font-bold'>
              Separate your clothes by each service selected, into different
              bags.
            </h3>
            <p className='italic text-base'>
              Tip: put all clothes For wash, dry and fold in 1 bag, all clothes
              for wash, dry and iron in another
            </p>
          </div>
        </ReactCardFlip>

        {/* 3 */}
        <ReactCardFlip isFlipped={isFlipped3} flipDirection='horizontal'>
          <div
            onClick={handleThirdCardClick}
            className='rounded-full w-80 h-80 bg-primary flex flex-col gap-6 justify-center items-center text-base-100 p-10 text-center cursor-pointer hover:bg-primary-focus transition-all'
          >
            <h1 className='text-3xl font-bold'>3</h1>
            <p className='text-xl font-semibold'>
              Wait for one of our friendly drivers to come and collect, then sit
              back and relax!
            </p>
          </div>
          <div
            onClick={handleThirdCardClick}
            className='rounded-full w-80 h-80 bg-primary flex flex-col gap-2 justify-center items-center text-base-100 p-10 text-center cursor-pointer hover:bg-primary-focus transition-all'
          >
            <h3 className='text-xl font-bold'>
              We will aim to collect within the working day of the collection
              date but we cannot give a specific time window. Your clothes will
              arrive back to you within the timeframe you specify at checkout!
            </h3>
          </div>
        </ReactCardFlip>
      </div>
    </div>
  );
};

export default CardFlip;
